import { isEmpty, isVideo, isHTML } from "@utils/wallet/numberFormet.util";
import ImageComponent from "src/components/secondary-wallet/imageComponent";
import VideoComponent from "src/components/secondary-wallet/videoComponent";
import IframeComponent from "src/components/secondary-wallet/iframeComponent";

interface IMediaImageComponent {
  mediaImage: string;
  mediaExtension: string;
  image: string;
  animationURL: string;
  secondImage: string;
  dynamicSize?: { height: string; width: string };
  page?: string;
  autoPlay?: boolean;
  mute?: boolean;
  singlePage?: boolean;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  objectFit?: "contain" | undefined;
  handleImagesize?: (height: string, width: string) => void;
  videoSize?: { height: string; width: string };
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const MediaImageComponent = ({
  mediaImage,
  mediaExtension,
  image,
  animationURL,
  secondImage,
  dynamicSize,
  page = "",
  autoPlay = false,
  mute = true,
  singlePage = false,
  objectFit,
  layout,
  videoSize = { height: "100%", width: "100%" },
  handleImagesize,
}: IMediaImageComponent) => {
  const getImageComponent = () => {
    // media image available
    if (!isEmpty(mediaImage)) {
      if (isVideo("." + mediaExtension)) {
        return (
          <VideoComponent
            animationURL={mediaImage}
            autoPlay={true}
            height={videoSize?.height}
            width={videoSize?.width}
          />
        );
      } else if (isHTML(mediaExtension)) {
        if (singlePage) {
          return (
            <IframeComponent src={mediaImage} width="100%" height="100%" />
          );
        } else {
          return (
            <ImageComponent
              image={image}
              secondImage={image ?? ""}
              height={dynamicSize?.height}
              width={dynamicSize?.width}
              layout={layout}
              objectFit={objectFit}
              autoPlay={autoPlay}
              mute={mute}
              handleImagesize={(height, width) => {
                handleImagesize?.(
                  height <= 4200 ? height + "px" : "4200px",
                  width + "px"
                );
              }}
              page={page}
            />
          );
        }
      } else {
        return (
          <ImageComponent
            image={mediaImage}
            secondImage={image ?? ""}
            height={dynamicSize?.height}
            width={dynamicSize?.width}
            layout={layout}
            objectFit={objectFit}
            autoPlay={autoPlay}
            mute={mute}
            handleImagesize={(height, width) => {
              handleImagesize?.(
                height <= 4200 ? height + "px" : "4200px",
                width + "px"
              );
            }}
            page={page}
          />
        );
      }
    } else {
      //else old logic
      return isEmpty(animationURL) ? (
        <ImageComponent
          image={!isEmpty(image) ? image : "/images/image_not_found.png"}
          secondImage={secondImage ?? ""}
          height={dynamicSize?.height}
          width={dynamicSize?.width}
          layout={layout}
          objectFit={objectFit}
          autoPlay={autoPlay}
          mute={mute}
          handleImagesize={(height, width) => {
            handleImagesize?.(
              height <= 4200 ? height + "px" : "4200px",
              width + "px"
            );
          }}
          page={page}
        />
      ) : (
        <>
          <VideoComponent
            animationURL={animationURL}
            autoPlay={true}
            height={videoSize?.height}
            width={videoSize?.width}
            layout={layout}
          />
        </>
      );
    }
  };
  return <>{getImageComponent()}</>;
};
