import { useProfile, UseSecondaryWalletMojitoMutation } from "@hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { EMojitoSecondaryWalletMutation } from "@state";
import {
  getAbiFile,
  getAccountProvider,
  getBalanceonChainID,
  getProviderType,
  OnSwitchNetwork,
} from "@utils";
import {
  SwapPrice,
  isApproval,
  unlockPrice,
  buyNftWithNativeCurrency,
  buyNftWithWethOrWmatic,
} from "@utils/wallet/contractApproval.util";
import { LOCAL_STORAGE_STATE } from "@utils/wallet/clearLocalStorage";
import {
  GetIpfsImage,
  isEmpty,
  roundUp,
  truncateDecimal,
  getPartyId,
} from "@utils/wallet/numberFormet.util";
import {
  IEstamateTaxItems,
  ISupportedCurrenciesList,
  ITaxResponse,
  network,
  NFTPrice,
  UsegetUSDPrice,
} from "@utils/wallet/wallet";
import { useWeb3, ConnectType } from "@utils/wallet/connectContext";
import { utils } from "ethers";
import React, { useEffect, useState } from "react";
import useLocalStorageState from "use-local-storage-state";
import Checkout from "../modal/checkout";
import {
  IAddress,
  BuyNowMakeOfferModal,
  selectedCurrencyProps,
} from "../modal/buyNowMakeOfferModal";
import { ConnectWalletErrorModel } from "../modal/secondary-wallet";
import ToastComponent from "@secondary/toast";
import { useRouter } from "next/router";
import { EEventNames } from "@enums";
import {
  checkoutStarted,
  checkoutStepCompleted,
  CheckoutType,
  ContentType,
  makeAnOfferClicked,
  offerCompleted,
  offerStepCompleted,
  PaymentMethod,
  RejectedBy,
  StepName,
  swapCompleted,
  transactionRejected,
} from "@analytics";
import { useNetwork } from "wagmi";

interface IDetails {
  orgId: string;
  nftNetworkDetails: network;
  NFTDetailsState: {
    price: NFTPrice[];
    latestOffer: {
      price: NFTPrice[];
    };
    nftTokenId: string;
    contractAddress: string;
    listedCurrency: string;
    network: string;
    orderId: string;
    metadata: {
      name: string;
      image: string;
      artistName: string;
      year: string;
      animationURL?: string;
    };
    tokenId: string;
    tokenURI: string;
    tokenType: string;
    ownerAddress: string;
    mediaImage: string;
    mediaExtension: string;
  };
  onRefectchData: () => void;
  supportedCurrencies: ISupportedCurrenciesList[] | null;
  mode: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
  handleOpenModal: () => void;
}

interface ImakeofferState {
  date: string;
  usd: number;
  crypto: number;
}

interface ILoader {
  reCheckBalance: boolean;
  errorText: boolean;
}

const BuyNowOrMakeOffer = ({
  NFTDetailsState,
  onRefectchData,
  orgId,
  supportedCurrencies,
  mode = "BuyNow",
  modalOpen = false,
  handleCloseModal,
  handleOpenModal,
  nftNetworkDetails,
}: IDetails): JSX.Element => {
  const router = useRouter();
  const { profile } = useProfile();
  const { getIdTokenClaims } = useAuth0();
  const auth0_id = profile?.userOrgs[0]?.externalUserId || "";
  const { connect: web3Connect } = useWeb3();
  const { chain } = useNetwork();
  const [connect, setConnect] = useState<any>({ currency: "WETH" });
  const [connectMetaMask] = useLocalStorageState<ConnectType>(
    LOCAL_STORAGE_STATE.secondary_wallet.connect_wallet,
    {}
  );

  useEffect(() => {
    async function fetchData() {
      if (chain) {
        const balance = await getBalanceonChainID(Number(chain?.id));
        setConnect((prevValue: any) => ({
          ...prevValue,
          wethOrWmaticBalance: String(balance["Wbalance"]) ?? "",
          ethOrMaticBalance: balance["balance"] ?? "",
          currency: NFTDetailsState?.price?.[1]?.unit ?? "",
        }));
      }
    }

    fetchData();
  }, [chain]);

  const [validateMetamask, setValidateMetamask] = useState<string>();
  const [openCheckoutModal, setOpenCheckoutModal] = useState<boolean>(false);
  const [makeOfferSwapAmount, setMakeOfferSwapAmount] = useState<string>("");
  const [isNativeCurrency, setIsNativeCurrency] = useState<boolean>(
    mode === "BuyNow" ? true : false
  );
  const [selectedCurrency, setSelectedCurrency] =
    useState<selectedCurrencyProps>({
      contractAddress: "",
      currency: "",
      unit: "",
      id: "",
      marketPlaceContractAddress: "",
    });
  const [checkoutType, setCheckoutType] = useState<string>("summary");
  const [latestPrice, setLatestPrice] = useState<NFTPrice[]>([]);
  const [orderId, setOrderId] = useState<string>("");
  const [processingTitle, setProcessingTitle] = useState<string>(
    "Waiting for Approval"
  );
  const [processingContent, setProcessingContent] = useState<string>(
    "Transaction in progress. Please do not close or reload the page..."
  );
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [updatedNftTokenId, setUpdatedNftTokenId] = useState<string>("");
  const [errorModel, setErrorModel] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState({
    toastOpen: false,
    msg: "",
  });
  const [buySellToast, setBuySellToast] = useState({
    toastOpen: false,
    msg: "",
  });
  const [offerButtonLoader, setOfferButtonLoader] = useState<string>("");
  const [isSwap, setIsSwap] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<ILoader>({
    reCheckBalance: false,
    errorText: false,
  });
  const [bidCurrency, setBidCurrency] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>(
    "An error was encountered while processing your payment. Please check wallet transaction or contact support team for more details."
  );

  const [makeOfferButtonDisable, setMakeOfferButtonDisable] =
    useState<boolean>(false);

  const [makeOfferValue, setMakeOfferValue] = useState<ImakeofferState>({
    date: "",
    crypto: 1,
    usd: 0,
  });
  const [Address, setAddress] = useState<IAddress>({
    postalCode: "",
    country: { value: "", lable: "" },
    state: "",
    city: "",
    street: "",
  });

  const [estimateTax, setEstimateTax] = useState<IEstamateTaxItems | any>({
    taxPercentage: 0,
    royaltyFee: 0,
    platformFee: 0,
  });

  const [taxResponse, setTaxResponse] = useState<ITaxResponse>();

  const { getUSDPrice, onrefectchUSDPrice } = UsegetUSDPrice({
    crypto: bidCurrency,
  });

  useEffect(() => {
    onrefectchUSDPrice();
  }, [bidCurrency]);

  const [initiateBuyNFT, { isLoading }] = UseSecondaryWalletMojitoMutation<{
    initiateBuyNFT: {
      proofOfApproval: string;
      order: {
        fixedPrice: number;
        price: {
          value: number;
          type: string;
          unit: string;
        };
        id: string;
        nftOwnerAddress: string;
        nftTokenId: string;
        quantity: number;
        tokenContract: string;
        tokenId: string;
        nftToken: {
          tokenMetadata: {
            name: string;
            description: string;
            image: string;
          };
        };
      };
    };
  }>(EMojitoSecondaryWalletMutation.initiateBuyNFT);

  const [updateTransactionHash] = UseSecondaryWalletMojitoMutation<{
    updateTransactionHash: {
      success: boolean;
      order: {
        id: string;
        nftTokenId: string;
        fixedPrice: string;
        price: {
          value: string;
          unit: string;
          type: string;
        };
      };
      newNFTID: string;
    };
  }>(EMojitoSecondaryWalletMutation.updateTransactionHash);

  const [createOffer] = UseSecondaryWalletMojitoMutation<{
    nftTokenId: string;
    orgId: string;
    buyerAddress: string;
    offerPrice: number;
    expiryDate: string;
    paymentToken: string;
    currencyId: string;
  }>(EMojitoSecondaryWalletMutation.createOffer);

  const [submitProofOfOffer] = UseSecondaryWalletMojitoMutation<{
    orderId: string;
    orgId: string;
    signature: string;
  }>(EMojitoSecondaryWalletMutation.submitProofOfOffer);

  const checkNetworkAndBalance = async () => {
    const Pricevalue =
      mode === "BuyNow"
        ? +NFTDetailsState.price[1].value
        : makeOfferValue?.crypto;
    const totalAmount = Pricevalue + +estimateTax?.taxPercentage;
    const swapAmount = truncateDecimal(
      totalAmount - Number(connect?.wethOrWmaticBalance),
      4
    );
    const totalAmountWithTax = truncateDecimal(
      Pricevalue + Number(estimateTax?.taxPercentage),
      4
    );

    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const { chainId } = await provider.getNetwork();
    if (nftNetworkDetails?.chainID !== chainId) {
      setValidateMetamask("wrongNetwork");
      setOfferButtonLoader("");
    } else if (
      isNativeCurrency &&
      truncateDecimal(Number(connect?.ethOrMaticBalance), 4) <
        totalAmountWithTax
    ) {
      setValidateMetamask("checkBalance");
      setIsSwap(false);
    } else if (
      !isNativeCurrency &&
      truncateDecimal(Number(connect?.wethOrWmaticBalance), 4) <
        totalAmountWithTax
    ) {
      if (
        truncateDecimal(Number(connect?.ethOrMaticBalance), 4) >= swapAmount
      ) {
        setIsSwap(true);
        setValidateMetamask("checkBalance");
      } else {
        setValidateMetamask("checkBalance");
        setIsSwap(false);
      }
    } else {
      setValidateMetamask("no warnings");
    }
  };

  useEffect(() => {
    if (openCheckoutModal && checkoutType === "summary") {
      checkNetworkAndBalance();
    }
  }, [connectMetaMask, connect, openCheckoutModal]);

  const getPriceMakeOffer = (data: ImakeofferState) => {
    return [
      {
        value: data?.usd,
        unit: "USD",
        type: "FIAT",
      },
      {
        value: data?.crypto,
        unit: NFTDetailsState?.price?.[1]?.unit ?? "",
        type: "CRYPTO",
      },
    ];
  };

  const fetchBalance = async () => {
    if (web3Connect?.connected && web3Connect?.account && chain) {
      const balance = await getBalanceonChainID(Number(chain?.id));
      setConnect((prevValue: any) => ({
        ...prevValue,
        wethOrWmaticBalance: String(balance["Wbalance"]),
        ethOrMaticBalance: balance["balance"],
      }));
    }
  };

  const handleSwitchNetwork = async () => {
    setOfferButtonLoader("");
    setMakeOfferButtonDisable(false);
    try {
      const hexValue = parseInt(
        String(nftNetworkDetails?.chainID),
        10
      ).toString(16);
      const chain = hexValue.padStart(6, "");
      setCheckoutType("processing");
      setProcessingTitle("Waiting for Confirmation");
      setProcessingContent(
        `Follow wallet prompts to confirm the switch network. Please do not close or reload the page...`
      );
      await OnSwitchNetwork(chain);
      setCheckoutType("summary");
      setValidateMetamask("no warnings");
      fetchBalance();
    } catch (error: any) {
      if (error?.message === "User rejected the request.") {
        setCheckoutType("processing");
        setIsLoader({
          ...isLoader,
          errorText: true,
        });
        setOpenCheckoutModal(false);
        setErrorModel(true);
        setErrorMessage(
          "Network switch has been cancelled by user. Please try again."
        );
      } else {
        setCheckoutType("processing");
        setIsLoader({
          ...isLoader,
          errorText: true,
        });
        setOpenCheckoutModal(false);
        setErrorModel(true);
        setErrorMessage(
          "An error was encountered while processing the switch network."
        );
      }
    }
  };

  const swapLoaderStop = async () => {
    const partyId = await getPartyId(getIdTokenClaims);
    swapCompleted({
      auth0_id: auth0_id,
      customer_id: partyId,
      product_id: NFTDetailsState?.nftTokenId,
      product_name: NFTDetailsState?.metadata?.name,
      currency: selectedCurrency?.id,
      revenue: +makeOfferSwapAmount,
      total: +makeOfferSwapAmount,
    });
    setIsSwap(true);
    setCheckoutType("success");
    fetchBalance();
    setTimeout(() => {
      setValidateMetamask("remove warning");
      setIsSwap(false);
      setCheckoutType("summary");
      setOpenCheckoutModal(true);
      handleCloseModal();
    }, 2000);
  };

  const swapLoaderStart = (finalPrice: number) => {
    setOpenCheckoutModal(true);
    setProcessingTitle("Approve Swap");
    setProcessingContent(
      `Follow wallet prompt to complete the swap of ${truncateDecimal(
        finalPrice,
        4
      )} ${
        connect.currency === "WETH" ? "WETH" : "WMATIC"
      } from ${truncateDecimal(finalPrice, 4)} ${
        connect.currency === "WETH" ? "ETH" : "MATIC"
      }. Please do not close or reload the page...`
    );
    setCheckoutType("processing");
  };

  const swapWaitForTransaction = (finalPrice: number, hash: string) => {
    setProcessingTitle("Awaiting Confirmation");
    setProcessingContent(
      `Transaction in progress. Once confirmations are completed, you will be credited with ${truncateDecimal(
        finalPrice,
        4
      )} ${connect.currency === "WETH" ? "WETH" : "WMATIC"}...`
    );
    setTransactionHash(hash);
    setCheckoutType("processing");
  };

  const handleCloseCheckoutModal = () => {
    setValidateMetamask("remove warning");
    setOpenCheckoutModal(false);
    setOfferButtonLoader("");
  };

  const isWethAprrovalLoaderStart = () => {
    // setCheckoutType("processing");
  };

  const isWethAprrovalLoaderStop = () => {
    // setCheckoutType("processing");
  };

  const unLockWethLoaderStart = () => {
    setProcessingTitle("Waiting for Approval");
    setProcessingContent(
      `Follow wallet prompts to approve ${
        connect.currency === "WETH" ? "WETH" : "WMATIC"
      } from your wallet. Please do not close or reload the page...`
    );
    setCheckoutType("processing");
  };

  const unLockWaitForTransaction = (hash: string) => {
    setProcessingTitle("Waiting for Approval");
    setProcessingContent(
      "Transaction in progress. Please do not close or reload the page..."
    );
    setTransactionHash(hash);
    setCheckoutType("processing");
  };

  const unLockWethLoaderStop = () => {
    setProcessingTitle("Processing Payment");
    setProcessingContent(
      "Follow wallet prompts to confirm the transaction. Please do not close or reload the page…"
    );
    setCheckoutType("processing");
  };

  const unLockWethError = (e?: string) => {
    setCheckoutType("processing");
    setOpenCheckoutModal(false);
    setIsLoader({
      ...isLoader,
      errorText: false,
    });
    setErrorModel(true);
    setErrorMessage(
      e ??
        "An error was encountered while processing your payment. Please check wallet transaction or contact support team for more details."
    );
  };

  const unLockWethRejectError = () => {
    TriggerCheckOutevent(
      mode === "BuyNow"
        ? EEventNames?.buyNowApprovalRejected
        : EEventNames.makeOfferApprovalRejected
    );
    setCheckoutType("processing");
    setOpenCheckoutModal(false);
    setIsLoader({
      ...isLoader,
      errorText: false,
    });
    setErrorModel(true);
    setErrorMessage(
      "Transaction has been cancelled by user. Please try again."
    );
  };

  const errorBuyNow = (e: string | undefined) => {
    setProcessingTitle("Processing Payment");
    setOpenCheckoutModal(false);
    setIsLoader({
      ...isLoader,
      errorText: false,
    });
    setErrorModel(true);
    if (e && e?.includes("insufficient amount")) {
      setErrorMessage(
        "There is an insufficient balance in your wallet to complete this transaction inclusive of gas fees."
      );
    } else {
      setErrorMessage(
        "An error was encountered while processing your payment. Please check wallet transaction or contact support team for more details."
      );
    }
  };

  const rejectBuyNow = () => {
    TriggerRejectionEvents({
      auth0_id: auth0_id,
      customer_id: profile?.user?.id,
      buyer_address: `${web3Connect?.account}` ?? "",
      product_id: NFTDetailsState?.nftTokenId,
    });
    setProcessingTitle("Processing Payment");
    setOpenCheckoutModal(false);
    setIsLoader({
      ...isLoader,
      errorText: false,
    });
    setErrorModel(true);
    setErrorMessage(
      "Transaction has been cancelled by the user. Please try again."
    );
  };

  const buyNowWaitForTransaction = (hash: string) => {
    setProcessingTitle("Processing Payment");
    setProcessingContent(
      "Transaction in progress. Please do not close or reload the page..."
    );
    setCheckoutType("processing");
    setTransactionHash(hash);
  };

  const buyNowLoaderStart = () => {
    setCheckoutType("processing");
  };

  const buyNowLoaderStop = () => {
    setCheckoutType("processing");
  };

  const buyNowSuccess = (e: string, order: string) => {
    setTransactionHash(e);
    TriggerCheckOutevent(EEventNames?.buyNowMetaMaskCompleted);
    updateTransactionHash({
      nftTokenId: NFTDetailsState?.nftTokenId,
      orgId: orgId,
      transactionHash: e,
      buyerAddress: web3Connect?.account,
      orderId: order?.length > 0 ? order : orderId,
    })
      .then((res: any) => {
        if (
          ["PENDING"].includes(
            res?.updateTransactionHash?.acknowledgementStatus
          )
        ) {
          //acknowledgementStatus PENDING rehit API after 5 sec
          setTimeout(() => {
            buyNowSuccess(e, orderId);
          }, 2000);
        } else {
          TriggerCheckOutevent(EEventNames?.buyNowCompleted);
          setCheckoutType("success");
          setLatestPrice(res?.updateTransactionHash?.order?.price);
          if ([router.asPath].includes("/marketplace/asset/details/")) {
            onRefectchData();
          }
          setCheckoutType("confirm");
        }
      })
      .catch(() => {
        setCheckoutType("success");
        setLatestPrice(NFTDetailsState?.price);
        setTimeout(() => {
          onRefectchData();
          setCheckoutType("confirm");
        }, 2000);
        // setCheckoutType("summary");
        // setOpenCheckoutModal(false);
        // setErrorModel(true);
      });
  };

  const onSubmitMakeOffer = () => {
    setOfferButtonLoader("");
    createOffer({
      nftTokenId: NFTDetailsState?.nftTokenId,
      orgId: orgId,
      buyerAddress: web3Connect?.account,
      offerPrice: makeOfferValue?.crypto,
      expiryDate: makeOfferValue?.date,
      paymentToken: selectedCurrency?.contractAddress,
      currencyId: selectedCurrency?.id,
      cryptoTax: {
        ...taxResponse,
      },
      taxAddress: {
        street1: Address?.street,
        city: Address?.city,
        state: Address?.state,
        postalCode: Address?.postalCode,
        country: Address?.country?.value,
      },
    })
      .then(async (res: any) => {
        setMakeOfferButtonDisable(false);
        try {
          const providerType = getProviderType();
          const provider = await getAccountProvider(providerType);
          const signer = provider?.getSigner();
          const messageToSign = utils.arrayify(res?.createOffer?.messageToSign);
          const signature = await signer?.signMessage(messageToSign);
          TriggerCheckOutevent(EEventNames.makeOfferMetaMaskCompleted);
          // Submit Approval
          submitProofOfOffer({
            orgId: orgId,
            orderId: res?.createOffer?.order?.id,
            signature: signature,
          })
            .then(() => {
              TriggerCheckOutevent(EEventNames.makeOfferCompleted);
              setOpenCheckoutModal(true);
              setMakeOfferButtonDisable(false);
              setCheckoutType("submitted");
              onRefectchData();
            })
            .catch((e) => {
              if (e) {
                setOfferButtonLoader("");
                setMakeOfferButtonDisable(false);
                setCheckoutType("summary");
                setOpenCheckoutModal(false);
                setErrorMessage(
                  e?.message ??
                    "An error was encountered while processing your payment"
                );
                setErrorModel(true);
              }
            });
        } catch (e: any) {
          if (e) {
            setOpenCheckoutModal(false);
            setOfferButtonLoader("");
            setMakeOfferButtonDisable(false);
            setErrorModel(true);
            if (
              e.message?.includes("user rejected transaction") ||
              e.message?.includes("User denied transaction signature") ||
              e.message?.includes("user rejected signing")
            ) {
              setErrorMessage(
                "Signature request has been cancelled by the user. Please try again."
              );
              TriggerRejectionEvents({
                auth0_id: auth0_id,
                customer_id: profile?.user?.id,
                buyer_address: web3Connect?.account ?? "",
                product_id: NFTDetailsState?.nftTokenId,
              });
            }
          }
        }
      })
      .catch((e) => {
        if (e) {
          setErrorMessage(
            e?.message ??
              "An error was encountered while processing your payment"
          );
          setOfferButtonLoader("");
          setMakeOfferButtonDisable(false);
          setToastMsg({
            toastOpen: true,
            msg: e.message,
          });
          setCheckoutType("summary");
          setOpenCheckoutModal(false);
          setErrorModel(true);
        }
      });
  };

  const buyNow = () => {
    initiateBuyNFT({
      nftTokenId: NFTDetailsState?.nftTokenId,
      buyerAddress: `${web3Connect?.account}`,
      orgId: orgId,
      country: Address?.country?.value,
      postalCode: Address?.postalCode,
      currencyId: selectedCurrency?.id,
      cryptoTax: {
        ...taxResponse,
      },
      taxAddress: {
        street1: Address?.street,
        city: Address?.city,
        state: Address?.state,
        postalCode: Address?.postalCode,
        country: Address?.country?.value,
      },
    })
      .then(async (res) => {
        setOrderId(res?.initiateBuyNFT?.order?.id);
        if (isNativeCurrency) {
          buyNftWithNativeCurrency({
            tokenid: res?.initiateBuyNFT?.order?.tokenId,
            price: +truncateDecimal(res?.initiateBuyNFT?.order?.fixedPrice, 4),
            taxPercentage: +truncateDecimal(estimateTax?.taxPercentage, 4),
            owner: res?.initiateBuyNFT?.order?.nftOwnerAddress,
            contractAddress: NFTDetailsState?.contractAddress,
            proofOfApproval: res?.initiateBuyNFT?.proofOfApproval,
            random: res?.initiateBuyNFT?.order?.id,
            quantity: res?.initiateBuyNFT?.order?.quantity,
            currenyContractAddress: NFTDetailsState?.listedCurrency,
            selectedAddress: selectedCurrency?.contractAddress ?? "",
            marketPlaceContractAddress:
              selectedCurrency?.marketPlaceContractAddress,
            chainId: nftNetworkDetails?.chainID,
            buyNowLoaderStart: buyNowLoaderStart,
            buyNowLoaderStop: buyNowLoaderStop,
            buyNowSuccess: (e) =>
              buyNowSuccess(e, res?.initiateBuyNFT?.order?.id),
            buyNowWaitForTransaction: buyNowWaitForTransaction,
            errorBuyNow: errorBuyNow,
            rejectBuyNow: rejectBuyNow,
          });
        } else {
          buyNftWithWethOrWmatic({
            tokenid: res?.initiateBuyNFT?.order?.tokenId,
            price: +truncateDecimal(res?.initiateBuyNFT?.order?.fixedPrice, 4),
            taxPercentage: +truncateDecimal(estimateTax?.taxPercentage, 4),
            owner: res?.initiateBuyNFT?.order?.nftOwnerAddress,
            contractAddress: NFTDetailsState?.contractAddress,
            proofOfApproval: res?.initiateBuyNFT?.proofOfApproval,
            random: res?.initiateBuyNFT?.order?.id,
            quantity: res?.initiateBuyNFT?.order?.quantity,
            currenyContractAddress: NFTDetailsState?.listedCurrency,
            selectedAddress: selectedCurrency?.contractAddress ?? "",
            marketPlaceContractAddress:
              selectedCurrency?.marketPlaceContractAddress,
            chainId: nftNetworkDetails?.chainID,
            buyNowLoaderStart: buyNowLoaderStart,
            buyNowLoaderStop: buyNowLoaderStop,
            buyNowSuccess: (e) =>
              buyNowSuccess(e, res?.initiateBuyNFT?.order?.id),
            buyNowWaitForTransaction: buyNowWaitForTransaction,
            errorBuyNow: errorBuyNow,
            rejectBuyNow: rejectBuyNow,
          });
        }
      })
      .catch((e: any) => {
        setErrorMessage(
          e?.message ?? "An error was encountered while processing your payment"
        );
        setCheckoutType("summary");
        setOpenCheckoutModal(false);
        setErrorModel(true);
      });
  };

  const TriggerCheckOutevent = (
    eventName: string,
    crypto = "",
    choosedCurrency = ""
  ) => {
    setTimeout(async () => {
      const partyId = await getPartyId(getIdTokenClaims);
      if (eventName === EEventNames.makeOfferStarted) {
        makeAnOfferClicked({
          auth0_id: auth0_id,
          customer_id: partyId,
          creator: NFTDetailsState?.ownerAddress,
          currency: selectedCurrency?.currency,
          image_url: NFTDetailsState?.mediaImage,
          product_id: NFTDetailsState?.nftTokenId,
          product_type: NFTDetailsState?.tokenType,
          sku: NFTDetailsState?.nftTokenId,
          quantity: 1,
        });
      } else if (eventName === EEventNames.makeOfferCompleted) {
        offerCompleted({
          auth0_id: auth0_id,
          creator: NFTDetailsState?.ownerAddress,
          currency:
            choosedCurrency === ""
              ? selectedCurrency?.currency
              : choosedCurrency,
          price: crypto === "" ? makeOfferValue?.crypto : +crypto,
          product_name: NFTDetailsState?.metadata?.name,
          product_uuid: NFTDetailsState?.nftTokenId,
          product_type: NFTDetailsState?.tokenType,
          quantity: 1,
          offer_id: null,
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          offer_price: crypto === "" ? makeOfferValue?.crypto : +crypto,
          offer_number: "N/A",
          percent_of_list_price:
            crypto === "" ? makeOfferValue?.crypto : +crypto,
          sale_id: "SM001",
          sale_name: "SECONDARY MARKETPLACE",
          sku: NFTDetailsState?.nftTokenId,
          product_id: NFTDetailsState?.nftTokenId,
        });
      } else if (
        eventName === EEventNames.makeOfferApprovalCompleted ||
        eventName === EEventNames.makeOfferMetaMaskCompleted
      ) {
        offerStepCompleted({
          auth0_id: auth0_id,
          creator: NFTDetailsState?.ownerAddress,
          currency:
            choosedCurrency === ""
              ? selectedCurrency?.currency
              : choosedCurrency,
          price: crypto === "" ? makeOfferValue?.crypto : +crypto,
          product_name: NFTDetailsState?.metadata?.name,
          product_uuid: NFTDetailsState?.nftTokenId,
          product_type: NFTDetailsState?.tokenType,
          quantity: 1,
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          offer_price: crypto === "" ? makeOfferValue?.crypto : +crypto,
          step: eventName === EEventNames.makeOfferApprovalCompleted ? 1 : 2,
          step_name: StepName.Submit,
        });
      } else if (eventName === EEventNames.buyNowCheckOut) {
        checkoutStarted({
          auth0_id: auth0_id,
          cart_id: String(+new Date()),
          checkout_type: CheckoutType.MetaverseMarket,
          content_type: ContentType.MetaverseMarket,
          currency:
            choosedCurrency === ""
              ? selectedCurrency?.currency
              : choosedCurrency,
          customer_id: partyId,
          revenue:
            +estimateTax?.taxPercentage + NFTDetailsState?.price[1]?.value,
        });
      } else if (
        eventName === EEventNames.buyNowApprovalCompleted ||
        eventName === EEventNames.buyNowCompleted ||
        eventName === EEventNames.buyNowMetaMaskCompleted
      ) {
        let step = 1;
        if (eventName === EEventNames.buyNowMetaMaskCompleted) {
          step = 2;
        } else if (eventName === EEventNames.buyNowCompleted) {
          step = 3;
        }
        checkoutStepCompleted({
          auth0_id: auth0_id,
          cart_id: String(+new Date()),
          checkout_type: CheckoutType.MetaverseMarket,
          content_type: ContentType.MetaverseMarket,
          products: [
            {
              period_general: "N/A",
              creator: NFTDetailsState?.ownerAddress,
              product_id: NFTDetailsState?.nftTokenId,
              product_uuid: NFTDetailsState?.nftTokenId,
              price: NFTDetailsState?.price[1]?.value,
              product_name: NFTDetailsState?.metadata?.name,
              quantity: 1,
              sale_uuid: NFTDetailsState?.nftTokenId,
            },
          ],
          customer_id: partyId,
          in_app: false,
          num_products: 1,
          payment_method: PaymentMethod.Crypto,
          revenue:
            +estimateTax?.taxPercentage + NFTDetailsState?.price[1]?.value,
          sale_id: "SM001",
          sale_name: "SECONDARY MARKETPLACE",
          shipping_method: "N/A",
          step,
          step_name: StepName.Submit,
        });
      }
    }, 500);
  };

  const TriggerRejectionEvents = (data: any) => {
    setTimeout(async () => {
      const partyId = await getPartyId(getIdTokenClaims);
      transactionRejected({
        auth0_id: auth0_id,
        creator: NFTDetailsState?.ownerAddress,
        customer_id: partyId,
        department_category: "Secondary MarketPlace",
        price: +NFTDetailsState?.price[1]?.value,
        product_id: data?.product_id,
        rejected_by: RejectedBy.Buyer,
        transaction_type: "crypto",
      });
    }, 500);
  };

  const checkApproval = async (status: boolean) => {
    const isApproved = await isApproval({
      price:
        mode === "BuyNow"
          ? NFTDetailsState.price[1].value
          : makeOfferValue?.crypto,
      taxPercentage: estimateTax?.taxPercentage,
      abiFile: getAbiFile(selectedCurrency?.unit),
      currenyContractAddress: selectedCurrency?.contractAddress,
      marketPlaceContractAddress: selectedCurrency?.marketPlaceContractAddress,
      isWethAprrovalLoaderStart: isWethAprrovalLoaderStart,
      isWethAprrovalLoaderStop: isWethAprrovalLoaderStop,
      status: status,
    });
    return isApproved;
  };

  const handleBuyNowOrMakeOffer = async (name: string) => {
    TriggerCheckOutevent(
      mode === "BuyNow"
        ? EEventNames?.buyNowCheckOut
        : EEventNames.makeOfferCheckOut
    );
    setOfferButtonLoader(name);
    setMakeOfferButtonDisable(true);
    const Pricevalue =
      mode === "BuyNow"
        ? +NFTDetailsState?.price[1]?.value
        : makeOfferValue?.crypto;
    const totalAmount = Pricevalue + estimateTax?.taxPercentage;
    const swapAmount = truncateDecimal(
      totalAmount - Number(connect?.wethOrWmaticBalance),
      4
    );

    const providerType = getProviderType();
    const provider = await getAccountProvider(providerType);
    const { chainId } = await provider.getNetwork();
    if (nftNetworkDetails?.chainID !== chainId) {
      setValidateMetamask("wrongNetwork");
      setOfferButtonLoader("");
    } else if (
      isNativeCurrency &&
      truncateDecimal(Number(connect?.ethOrMaticBalance), 4) <
        truncateDecimal(totalAmount, 4)
    ) {
      setValidateMetamask("checkBalance");
      setIsSwap(false);
    } else if (
      !isNativeCurrency &&
      truncateDecimal(Number(connect?.wethOrWmaticBalance), 4) <
        truncateDecimal(totalAmount, 4)
    ) {
      if (
        +truncateDecimal(Number(connect?.ethOrMaticBalance), 4) >= +swapAmount
      ) {
        setIsSwap(true);
        setValidateMetamask("checkBalance");
      } else {
        setValidateMetamask("checkBalance");
        setIsSwap(false);
      }
    } else {
      if (isNativeCurrency) {
        if (mode === "BuyNow") {
          unLockWethLoaderStop();
          buyNow();
        } else {
          // Create Offer
          unLockWethLoaderStop();
          onSubmitMakeOffer();
        }
      } else {
        try {
          const isApproved = await checkApproval(false);
          if (isApproved) {
            if (mode === "BuyNow") {
              unLockWethLoaderStop();
              buyNow();
            } else {
              // Create Offer
              unLockWethLoaderStop();
              onSubmitMakeOffer();
            }
          } else {
            const getApprove = await unlockPrice({
              price:
                mode === "BuyNow"
                  ? NFTDetailsState.price[1].value
                  : makeOfferValue?.crypto,
              taxPercentage: estimateTax?.taxPercentage,
              abiFile: getAbiFile(selectedCurrency?.unit),
              currenyContractAddress: selectedCurrency?.contractAddress,
              marketPlaceContractAddress:
                selectedCurrency?.marketPlaceContractAddress,
              unLockWethLoaderStart: unLockWethLoaderStart,
              unLockWethLoaderStop: unLockWethLoaderStop,
              unLockWethError: unLockWethError,
              unLockWaitForTransaction: unLockWaitForTransaction,
              unLockWethRejectError: unLockWethRejectError,
            });
            if (getApprove) {
              const isApproved = await checkApproval(true);
              if (isApproved === "success") {
                TriggerCheckOutevent(
                  mode === "BuyNow"
                    ? EEventNames?.buyNowApprovalCompleted
                    : EEventNames.makeOfferApprovalCompleted
                );
                if (mode === "BuyNow") {
                  buyNow();
                } else {
                  onSubmitMakeOffer();
                }
              } else {
                setOfferButtonLoader("");
                unLockWethError(
                  `You don't have enough approval or balance of ${
                    mode === "BuyNow"
                      ? NFTDetailsState?.price[1]?.value
                      : makeOfferValue?.crypto
                  } WETH to complete this purchase. Please try again or increase allowance.`
                );
              }
            } else {
              setOfferButtonLoader("");
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleSwapCancel = () => {
    setCheckoutType("summary");
  };

  const handleSwapAmount = (
    val: number,
    taxPercent: number,
    usdAmount: number,
    date: string,
    address: IAddress,
    choosedCurrency: selectedCurrencyProps,
    taxResponseInput: ITaxResponse | null
  ) => {
    try {
      setUpdatedNftTokenId("");
      setSelectedCurrency({
        contractAddress: choosedCurrency?.contractAddress,
        id: choosedCurrency?.id,
        unit: choosedCurrency?.unit,
        marketPlaceContractAddress: choosedCurrency?.marketPlaceContractAddress,
        currency: choosedCurrency?.currency,
      });
      setEstimateTax({
        ...estimateTax,
        taxPercentage: taxPercent,
      });
      if (taxResponseInput) {
        setTaxResponse({
          ...taxResponseInput,
        });
      }
      handleCloseModal();
      setMakeOfferButtonDisable(false);
      setOfferButtonLoader("");
      setMakeOfferValue({
        crypto: Number(val),
        usd: Number(usdAmount),
        date: date,
      });
      setAddress(address);
      setIsNativeCurrency(false);
      const Pricevalue = Number(val);
      const totalAmount = +truncateDecimal(+taxPercent + Pricevalue, 4);
      const finalPrice = totalAmount - Number(connect?.wethOrWmaticBalance);
      setMakeOfferSwapAmount(roundUp(finalPrice, 4)?.toString());
      SwapPrice({
        abiFile: getAbiFile(selectedCurrency?.unit),
        price: finalPrice,
        wethAddress: openCheckoutModal
          ? selectedCurrency?.contractAddress
          : choosedCurrency?.contractAddress,
        swapLoaderStart: () => swapLoaderStart(+truncateDecimal(finalPrice, 4)),
        swapWaitForTransaction: (hash) =>
          swapWaitForTransaction(+truncateDecimal(finalPrice, 4), hash),
        swapLoaderStop: swapLoaderStop,
        errorSwap: unLockWethError,
        rejectSwap: unLockWethRejectError,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onClickBuynow = (
    choosedCurrency: selectedCurrencyProps,
    address: IAddress,
    taxResponseInput: ITaxResponse | null
  ) => {
    setMakeOfferButtonDisable(false);
    setOfferButtonLoader("");
    setUpdatedNftTokenId("");
    setToastMsg({ toastOpen: false, msg: "" });
    setCheckoutType("summary");
    setAddress(address);
    setSelectedCurrency({
      contractAddress: choosedCurrency?.contractAddress,
      currency: choosedCurrency?.currency,
      unit: choosedCurrency?.unit,
      id: choosedCurrency?.id,
      marketPlaceContractAddress: choosedCurrency?.marketPlaceContractAddress,
    });
    setEstimateTax({
      ...estimateTax,
      taxPercentage: taxResponseInput?.cryptoTaxPrice,
    });
    if (taxResponseInput) {
      setTaxResponse({
        ...taxResponseInput,
      });
    }
    if (
      choosedCurrency?.unit?.toUpperCase() === "MATIC" ||
      choosedCurrency?.unit?.toUpperCase() === "ETH"
    ) {
      setIsNativeCurrency(true);
      handleCloseModal();
      setOpenCheckoutModal(true);
    } else {
      setIsNativeCurrency(false);
      handleCloseModal();
      setOpenCheckoutModal(true);
    }
    TriggerCheckOutevent(EEventNames.buyNowStarted);
  };

  const onClickMakeOffer = (
    crypto: number,
    usd: number,
    date: string,
    address: IAddress,
    choosedCurrency: selectedCurrencyProps,
    taxResponseInput: ITaxResponse | null
  ) => {
    setUpdatedNftTokenId("");
    setMakeOfferButtonDisable(false);
    setCheckoutType("summary");
    setAddress(address);
    setMakeOfferValue({
      ...makeOfferValue,
      crypto: crypto,
      usd: usd,
      date: date,
    });
    setSelectedCurrency({
      contractAddress: choosedCurrency?.contractAddress,
      currency: choosedCurrency?.currency,
      unit: choosedCurrency?.unit,
      id: choosedCurrency?.id,
      marketPlaceContractAddress: choosedCurrency?.marketPlaceContractAddress,
    });
    setEstimateTax({
      ...estimateTax,
      taxPercentage: taxResponseInput?.cryptoTaxPrice,
    });

    TriggerCheckOutevent(
      EEventNames.makeOfferStarted,
      String(crypto),
      choosedCurrency?.currency
    );
    if (taxResponseInput) {
      setTaxResponse({
        ...taxResponseInput,
      });
    }
    if (
      choosedCurrency?.unit?.toUpperCase() === "ETH" ||
      choosedCurrency?.unit?.toUpperCase() === "MATIC"
    ) {
      setIsNativeCurrency(true);
      handleCloseModal();
      setOpenCheckoutModal(true);
    } else {
      setIsNativeCurrency(false);
      handleCloseModal();
      setOpenCheckoutModal(true);
    }
  };

  return (
    <div>
      {buySellToast.toastOpen && (
        <ToastComponent
          type={"error"}
          message={buySellToast.msg || ""}
          open={buySellToast.toastOpen || false}
          handleClose={() => {
            setBuySellToast({ toastOpen: false, msg: "" });
          }}
        />
      )}
      {openCheckoutModal && (
        <Checkout
          handleBuyNowOrMakeOffer={handleBuyNowOrMakeOffer}
          modalOpen={openCheckoutModal}
          buttonDisable={makeOfferButtonDisable}
          handleClose={handleCloseCheckoutModal}
          toastMsg={toastMsg}
          handleCloseToast={() => setToastMsg({ toastOpen: false, msg: "" })}
          types={checkoutType}
          buttonLoader={offerButtonLoader}
          quantity={1}
          loading={isLoading}
          warnings={validateMetamask}
          price={
            mode === "BuyNow"
              ? NFTDetailsState?.price
              : getPriceMakeOffer(makeOfferValue)
          }
          connect={{
            buyerAddress: `${web3Connect?.account}`,
            chainId: Number(chain?.id),
            currency: String(connect?.currency),
            ethOrMaticBalance: +truncateDecimal(
              Number(connect?.ethOrMaticBalance),
              4
            ),
            wethOrWmaticBalance: +truncateDecimal(
              Number(connect?.wethOrWmaticBalance),
              4
            ),
          }}
          transactionHash={transactionHash}
          isBuy={mode === "BuyNow"}
          processingTitle={processingTitle}
          processingContent={processingContent}
          isSwap={isSwap}
          NFTDetailsState={{
            image: NFTDetailsState?.metadata?.image,
            name:
              NFTDetailsState?.metadata?.name?.length > 0
                ? NFTDetailsState?.metadata?.name
                : "#" + NFTDetailsState?.tokenId,
            network: NFTDetailsState?.network,
            animationURL: NFTDetailsState?.metadata?.animationURL,
            nftTokenId: NFTDetailsState?.nftTokenId,
            mediaImage: NFTDetailsState?.mediaImage,
            mediaExtension: NFTDetailsState?.mediaExtension,
            contractAddress: NFTDetailsState?.contractAddress,
            tokenId: NFTDetailsState?.tokenId,
            tokenType: NFTDetailsState?.tokenType,
            ownerAddress:
              mode === "BuyNow"
                ? `${web3Connect?.account}` ?? ""
                : NFTDetailsState?.ownerAddress,
          }}
          handleSwapAmount={(
            val: number,
            taxPercent: number,
            usdAmount: number,
            address: IAddress,
            choosedCurrency: selectedCurrencyProps
          ) =>
            handleSwapAmount(
              val,
              taxPercent,
              usdAmount,
              "",
              address,
              choosedCurrency,
              null
            )
          }
          handleSwapCancel={handleSwapCancel}
          handleSwitchNetwork={handleSwitchNetwork}
          makeOfferSwapAmount={makeOfferSwapAmount}
          handleRefetchBalance={() => {
            setIsLoader({
              ...isLoader,
              reCheckBalance: true,
            });
            setOfferButtonLoader("");
            setMakeOfferButtonDisable(false);
            fetchBalance();
            setTimeout(() => {
              setIsLoader({
                ...isLoader,
                reCheckBalance: false,
              });
            }, 1000);
            checkNetworkAndBalance();
          }}
          orderId={orderId}
          latestPrice={latestPrice}
          isRecheck={isLoader?.reCheckBalance}
          taxPercentage={+truncateDecimal(estimateTax?.taxPercentage, 4)}
          selectedCurrencydata={selectedCurrency}
          onRefetchData={onRefectchData}
          address={Address}
          cryptoBID={getUSDPrice?.amount ? +getUSDPrice?.amount : 0.5}
          updateNftTokenId={updatedNftTokenId}
        />
      )}
      {modalOpen && nftNetworkDetails && connect && (
        <BuyNowMakeOfferModal
          makeOfferModel={modalOpen}
          handleSwapAmount={(
            val: number,
            taxPercentage: number,
            usdAmount: number,
            date: string,
            address: IAddress,
            choosedCurrency: selectedCurrencyProps,
            taxResponseInput: ITaxResponse | null
          ) =>
            handleSwapAmount(
              val,
              taxPercentage,
              usdAmount ?? 0,
              date,
              address,
              choosedCurrency,
              taxResponseInput
            )
          }
          handleSwitchNetwork={handleSwitchNetwork}
          image={
            isEmpty(NFTDetailsState?.metadata?.animationURL)
              ? NFTDetailsState?.metadata?.image ?? ""
              : NFTDetailsState?.metadata?.animationURL ?? ""
          }
          NftNetworkDetails={nftNetworkDetails}
          NFTDetailsState={{
            nftTokenId: NFTDetailsState?.nftTokenId,
            orderId: NFTDetailsState?.orderId,
            latestOffer: NFTDetailsState?.latestOffer,
            price: NFTDetailsState?.price,
            tokenId: NFTDetailsState?.tokenId,
            contractAddress: NFTDetailsState?.contractAddress,
            name:
              NFTDetailsState?.metadata?.name?.length > 0
                ? NFTDetailsState?.metadata?.name
                : "#" + NFTDetailsState?.tokenId,
            artistName: NFTDetailsState?.metadata?.artistName,
            year: NFTDetailsState?.metadata?.year,
            chainId: nftNetworkDetails?.chainID,
            tokenURI: NFTDetailsState?.tokenURI,
            animationURL: GetIpfsImage(
              NFTDetailsState?.metadata?.animationURL ?? ""
            ),
            mediaExtension: NFTDetailsState?.mediaExtension,
            mediaImage:
              NFTDetailsState?.metadata.image || NFTDetailsState?.mediaImage,
          }}
          connectMetaMask={connect}
          cryptoBID={getUSDPrice?.amount ? +getUSDPrice?.amount : 0.5}
          isBuy={mode === "BuyNow"}
          orgId={orgId}
          supportedCurrencies={supportedCurrencies}
          onClickMakeOffer={onClickMakeOffer}
          onClickBuynow={onClickBuynow}
          onHandleClose={() => {
            handleCloseModal();
          }}
          onChangeCurrency={(currency: string) => {
            setBidCurrency(currency);
          }}
        />
      )}
      <ConnectWalletErrorModel
        modelopen={errorModel}
        handleClose={() => {
          setErrorModel(false);
        }}
        message={errorMessage}
        handleRetryPayment={() => {
          setCheckoutType("summary");
          setIsSwap(false);
          setValidateMetamask("No warnings");
          setErrorModel(false);
          handleOpenModal();
          setOpenCheckoutModal(false);
          setMakeOfferButtonDisable(false);
        }}
        isBuyNowOrMakeOffer={true}
        isSwitchNetwork={isLoader?.errorText}
      />
    </div>
  );
};

export default BuyNowOrMakeOffer;
