import { ClickAwayListener } from "@mui/base";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { EViewSize } from "@enums";
import React, { useState } from "react";
import { palette } from "@styles/theme";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface ISelect {
  selectValue?: string;
  dropDownData?: React.ReactNode;
  externalcss?: string;
}

const SelectDropdown = styled(
  ({ selectValue, dropDownData, externalcss = "", ...props }: ISelect) => {
    const [clickAway, setClickAway] = useState<boolean>(false);
    return (
      <div {...props} id={externalcss ? externalcss : ""}>
        <ClickAwayListener onClickAway={() => setClickAway(false)}>
          <Box
            className={"sort-input-change"}
            onClick={() => setClickAway(!clickAway)}>
            <Grid item xs={12} className={"center-align-menu"}>
              <Grid item xs={10}>
                <Typography
                  className={`menu-item-value ${clickAway ? "open" : ""}`}>
                  {selectValue}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {clickAway ? (
                  <KeyboardArrowUpIcon className="arrow-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="arrow-icon" />
                )}
              </Grid>
            </Grid>
            {clickAway && (
              <>
                {" "}
                {dropDownData ? (
                  <>
                    {dropDownData}
                    <p className="menu-last"></p>
                  </>
                ) : (
                  <Typography className="menu-item my-2">
                    No Data Found
                  </Typography>
                )}
              </>
            )}
          </Box>
        </ClickAwayListener>
      </div>
    );
  }
)`
  display: flex;
  align-items: center;
  .center-align-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: ${EViewSize.mobile}) {
    .collection-box {
      margin-top: 50px !important;
    }
  }

  .arrow-icon {
    color: ${palette.black};
    fontsize: 25px;
  }

  .sort-input-change {
    border: 1px solid ${palette.paginationText};
    border-radius: 28px;

    width: 100%;
    z-index: 9999999;
    background: ${palette.white};
    cursor: pointer;
    .sort-icon-adjustment {
      padding-right: 32px;
      svg {
        width: 15px;
      }
    }
  }

  .menu-item {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: ${palette.black};
    padding: 8px 32px;
    .menu-first {
      padding-top: 0px !important;
    }
    &:hover {
      background: ${palette.hoverColor};
      // box-shadow: inset 0 0 0 50px rgb(255 251 251 / 50%);
      border-radius: 25px;
    }
  }
  .menu-last {
    padding-top: 10px;
  }

  .open {
    padding: 16px 0px 8px 32px !important;
  }

  .menu-item-value {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: ${palette.black};
    padding: 16px 0px 16px 32px;
  }

  .dropdown-svg {
    svg {
      padding-right: 15px;
      width: 15px;
    }
  }

  .svg {
    width: 15px;
  }
`;

export default SelectDropdown;
