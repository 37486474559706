import React from "react";
import { CardMedia } from "@material-ui/core";
import Image from "next/image";
import {
  defaultimg,
  getCloudinaryImage,
  GetIpfsImage,
  getValidImageUrl,
  isImage,
  isVideo,
} from "@utils/wallet/numberFormet.util";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";

interface IVideoComponent {
  animationURL: string;
  width?: string;
  height?: string;
  layout?: any;
  autoPlay?: boolean;
  mute?: boolean;
}

const VideoComponent = ({
  animationURL,
  width = "100%",
  height = "100%",
  autoPlay = true,
  mute = true,
  layout = "fill",
}: IVideoComponent): JSX.Element => {
  return (
    <>
      {!getValidImageUrl(animationURL) ? (
        <Image
          src={"/images/image_not_found.png"}
          layout={"fill"}
          objectFit={"contain"}
          blurDataURL={LAZY_LOAD_IMAGE_BLUR}
          placeholder="blur"
          alt="Image not fount"
        />
      ) : isImage(animationURL) ? (
        <Image
          src={getCloudinaryImage(GetIpfsImage(animationURL, "IMAGE"))}
          width={width}
          height={height}
          onError={(e: any) => {
            defaultimg(e);
          }}
          layout={layout ? layout : "fill"}
          objectFit={"contain"}
          blurDataURL={LAZY_LOAD_IMAGE_BLUR}
          placeholder="blur"
          alt="Image loader"
        />
      ) : isVideo(animationURL) ? (
        <CardMedia
          component="video"
          sx={{ width: "100%", height: "100%" }}
          image={getCloudinaryImage(GetIpfsImage(animationURL, "IMAGE"))}
          title="animated-url"
          controls
          autoPlay={autoPlay}
          muted={mute}
        />
      ) : (
        <iframe
          style={{ width: "100%", height: "100%" }}
          className="iframe-style"
          src={getCloudinaryImage(GetIpfsImage(animationURL, "IMAGE"), false)}
          allowFullScreen
        />
      )}
    </>
  );
};

export default VideoComponent;
