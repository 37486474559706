import React from "react";
import CountrySelect from "src/components/countrySelect/CountrySelect";
import { useCountryOptions } from "./country";

export interface Ivalues {
    value: string;
    lable: string;
}

interface ICountry {
    lable: string;
    type: string;
    placeholder?: string;
    state?: string;
    value: Ivalues;
    onChangeData: (lable: string, value: Ivalues) => void;
    disabled: boolean;
}

const CountryStateSelector = ({ state = undefined, placeholder = "", lable, type, onChangeData, value, disabled }: ICountry): JSX.Element => {


    const { options } = useCountryOptions(state);
    
    return (
        <CountrySelect
            data={options}
            value={value}
            onChange={(newValue: Ivalues)=>{ 
                onChangeData(type, newValue)
            }}
            label={lable}
            placeholder={placeholder}
            disabled={disabled}
        />
    );
}

export default CountryStateSelector;