import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToastComponent: React.FC<{
  type: "success" | "info" | "warning" | "error" | undefined;
  message: string;
  open: boolean;
  handleClose: any;
}> = ({ type, message, open, handleClose }) => {
  return (
    <Snackbar
      style={{ top: "85px" }}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      key={"top" + "right"}
      onClose={handleClose}>
      <Alert severity={type} sx={{ width: "100%" }} key={"top" + "right"}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastComponent;
