import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CountrySelectStyle from './CountrySelect.module.css';
import Typography from '@material-ui/core/Typography';
import { palette } from '@styles/theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface CountryProps {
  onChange: (newValue: any) => void;
  value: any;
  label:string;
  placeholder?: string;
  data: {
    value: string;
    lable: string;
  }[];
  disabled: boolean;
}
const style={
  fontStyle:'normal',
  fontFamily:'BentonSans',
  fontWeight:700,
  fontSize:'10px',
  marginBottom:1,
  color: palette.black,
}
const CountrySelect = ({data, onChange, value, label, placeholder, disabled}: CountryProps): JSX.Element => {
  return (
    <div className={CountrySelectStyle.countrySelectWrapper}>
    <Typography sx={style}> {label}</Typography>
    <Autocomplete
      id="country-select-demo"
      options={data}
      autoHighlight
      getOptionLabel={(option) => option.lable}
      onChange={(e: any, newValue: any)=> { onChange(newValue); }}
      value={value}
      disableClearable={true}
      selectOnFocus={false}
      disabled={disabled}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: palette.black }} />}
      renderOption={(props, option) => (
        <Box className='menu-lable-li' component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <Box className='menu-lable' sx={{fontSize: '15px', fontFamily: "BentonSans"}}>
            {option.lable}
          </Box>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
        {...params}
         variant="standard"
         placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
    </div>
  );
}

export default CountrySelect;
