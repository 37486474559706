import { useMemo } from "react";
import { useCountryRegion } from "react-use-country-region";

interface RawCountry {
    countryName: string;
    countryShortCode: string;
}

export interface SelectOption {
    value: string;
    lable: string;
}

interface RawRegion {
    name: string;
    shortCode: string;
  }
  
const mapCountryToSelectOption = (country: RawCountry): SelectOption => ({
    lable: country.countryName,
    value: country.countryShortCode || country.countryShortCode || "",
});

const mapRegionToSelectOption = (region: RawRegion): SelectOption => ({
    lable: region.name,
    value: region.shortCode || region.name || "",
});


const UNAVAILABLE_COUNTRIES = [
    // eslint-disable-next-line array-element-newline
    "AF", "AL", "BB", "BS", "BW", "BY", "CF", "CU", "GH", "GW", "IQ", "IR", "JM", "KH", "KP",
    // eslint-disable-next-line array-element-newline
    "KS", "LY", "ML", "MM", "MN", "MU", "NI", "PA", "PK", "RU", "SD", "SO", "SS", "SY", "TT", "UA", "UG", "VE", "VI", "VU", "YE", "ZW",
  ];
  
const countryIsAvailable = (country: RawCountry) => !UNAVAILABLE_COUNTRIES.includes(country.countryShortCode);
  
export function useCountryOptions(countryCode?: string | number): any {
    const { result: country, getCountryList } = useCountryRegion(countryCode);
  
    return useMemo(() => {
      // Read about this line in useCountryOptionsBlacklistScript.js:
      (window as any).countries = getCountryList();
  
      // useCountryRegion seems to return null as the result (country) when countryCode changes. It takes an additional
      // render to get the right value, thus this check:
      if (countryCode === "" || (countryCode && !country)) return { options: [], optionsMap: {} };
  
      const options: SelectOption[] = country
        ? country.regions.map(mapRegionToSelectOption)
        : getCountryList()
          .filter(countryIsAvailable)
          .map(mapCountryToSelectOption);
  
      return { options };
  
    // getCountryList is not memoized. Adding it as a dependency makes this function always run, so we exclude it:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode, country]);
  }