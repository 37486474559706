import React, { useState } from "react";
import {
  isEmpty,
  truncateAddress,
  truncateDecimal,
  getStatus,
  getTokenAlignmentStatus,
  formatNumberToUSD,
} from "@utils/wallet/numberFormet.util";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import SVG from "react-inlinesvg";
import Box from "@mui/material/Box";
import { SectionDivider } from "@secondary/SectionDivider";
import { EViewSize } from "@enums";
import { MetadataAttributes, INFTDetails, network } from "@utils/wallet/wallet";
import { CheckNetworkTokens } from "@utils";
import { Avatar } from "@core/header/authMenu";
import { NextRouter } from "next/router";
import { MediaImageComponent } from "@layouts/profile/wallet/components/MediaImageComponet";

interface IData {
  data: { [k: string]: any };
  line?: boolean;
}

const NFTImageBox = {
  borderRadius: "0px",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  height: "auto",
  minHeight: "300px",
};

export const NFTPreview = styled(
  ({
    image,
    secondImage,
    animationURL,
    mediaImage,
    mediaExtension,
    ...props
  }: any) => {
    const [dynamicSize, setDynamicSize] = useState({
      height: "600px",
      width: "550px",
    });
    return (
      <Box sx={[NFTImageBox]} {...props}>
        <MediaImageComponent
          mediaImage={mediaImage}
          mediaExtension={mediaExtension}
          image={image}
          animationURL={animationURL}
          secondImage={secondImage}
          dynamicSize={dynamicSize}
          page="DETAILS"
          autoPlay={true}
          singlePage={true}
          handleImagesize={(height, width) => {
            setDynamicSize({ height: height, width: width });
          }}
        />
      </Box>
    );
  }
)`
  img {
    width: 100%;
    height: 100%;
  }
  .play-button {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
  .iframe-style {
    min-height: 500px;
    width: 100%;
    canvas {
      width: 350px;
      height: 350px;
    }
  }
`;

export const DetailsList = styled(({ data, ...props }: IData) => {
  return (
    <Grid container spacing={1} {...props}>
      {Object.keys(data).map((e, index: number) => {
        if (!isEmpty(data[e])) {
          return (
            <>
              {e === "EDITIONS:" ? (
                <Grid key={index} item xs={6} className="key">
                  <Typography className="details-key">{e}</Typography>
                  <Tooltip
                    title="Edition represents total supply available on NFT token"
                    placement="right"
                    arrow>
                    <span style={{ cursor: "pointer" }}>
                      <SVG src={"/icons/question.svg"} width={15} height={15} />
                    </span>
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Typography className="details-key">{e}</Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography align="right" className="details-obj">
                  {data[e]}
                </Typography>
              </Grid>
            </>
          );
        }
      })}
    </Grid>
  );
})`
  .key {
    display: inline-flex;
    span {
      display: flex;
      padding-left: 5px;
      margin-top: 1px;
    }
  }
  .details-key {
    font-family: BentonSans;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
  .details-obj {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 15px;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    .contract {
      cursor: pointer;
      font-family: BentonSans;
      font-weight: 400;
      font-size: 15px;
      text-decoration: underline;
    }
  }
`;

export const PropertiesList = styled(
  ({ data, line = true, ...props }: IData) => {
    return (
      <div {...props}>
        <Grid container spacing={0}>
          {Object.keys(data).map((e, index: number) => {
            if (!isEmpty(data[e])) {
              return (
                <>
                  {line && (
                    <Grid item xs={12}>
                      <hr
                        className={`divider-details ${
                          index === 0 ? "pd-19" : "pd-18"
                        }`}
                      />
                    </Grid>
                  )}
                  <Grid key={index} item xs={6}>
                    <Box>
                      <Typography className="details-key">{e}:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="right" className={"details-obj "}>
                      {data[e]}
                    </Typography>
                  </Grid>
                </>
              );
            }
          })}
        </Grid>

        {line && <SectionDivider className="my-5" />}
      </div>
    );
  }
)`
  .details-key {
    font-family: BentonSans;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
  .details-obj {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 15px;
    opacity: 1;
  }
  .prop-name {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .value {
    font-size: 15px;
  }
  span {
    opacity: 0.6;
  }

  .cus-point {
    cursor: pointer;
  }
  .divider-details {
    background: #ffffff;
    opacity: 0.2;
  }
  .pd-19 {
    margin-bottom: 19px;
  }
  .pd-18 {
    margin-bottom: 18px;
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .value {
      display: inline-grid;
      float: right;
    }
    .network-box {
      padding-right: 0px;
    }
  }
`;

interface IgetProperties {
  attributes: MetadataAttributes[] | any;
  tags?: string[];
  yearCreated: string;
  createdBy: string;
}
export const GetProperties = styled(
  ({
    attributes,
    yearCreated,
    tags = [],
    createdBy,
    ...props
  }: IgetProperties) => {
    const attributesData: MetadataAttributes[] | any = [...attributes];
    tags.map((ele) => {
      attributesData.push({
        traitType: `TAG`,
        value: {
          __typename: "AttributeValueString",
          stringValue: ele,
        },
        displayType: "",
        maxValue: 0,
        prevalance: 0,
      });
    });
    if (!isEmpty(yearCreated)) {
      attributesData.push({
        traitType: `YEAR CREATED`,
        value: {
          __typename: "AttributeValueString",
          stringValue: yearCreated,
        },
        displayType: "",
        maxValue: 0,
        prevalance: 0,
      });
    }
    if (!isEmpty(createdBy)) {
      attributesData.push({
        traitType: `ARTIST`,
        value: {
          __typename: "AttributeValueString",
          stringValue: createdBy,
        },
        displayType: "",
        maxValue: 0,
        prevalance: 0,
      });
    }
    const attributesSort = attributesData?.sort((a: any, b: any) =>
      a?.traitType?.localeCompare(b?.traitType)
    );
    const displayTypeNumber = attributesSort?.filter(
      (a: any) => a?.displayType === "number"
    );
    const displayTypeNotNumber = attributesSort?.filter(
      (a: any) =>
        a?.displayType !== "number" &&
        a?.prevalance > 0 &&
        a?.value?.__typename === "AttributeValueString"
    );
    const displayTypeNotNumberWithFloat = attributesSort?.filter(
      (a: any) =>
        a?.displayType !== "number" &&
        a?.prevalance > 0 &&
        a?.value?.__typename === "AttributeValueFloat"
    );
    const displayTypeNotNumberWithZeroPrevalance = attributesSort?.filter(
      (a: any) => a?.displayType !== "number" && a?.prevalance === 0
    );

    const getProperties = (data: MetadataAttributes, key = true) => {
      if (key) {
        if (data?.prevalance === 0) {
          return <>{`${data.traitType}`} </>;
        } else {
          if (data?.traitType && data?.value?.stringValue) {
            return (
              <>
                {`${data.traitType}:`}
                {"  "}
                <span> {`${data?.value?.stringValue}`}</span>
              </>
            );
          } else if (data?.traitType && data?.value?.floatValue) {
            return <>{`${data.traitType}:`}</>;
          } else if (data?.traitType && data?.value?.intValue) {
            return <>{`${data.traitType}:`}</>;
          }
          if (data?.traitType && data?.value?.boolValue) {
            return <>{`${data.traitType}:`}</>;
          }
        }
      } else {
        const prevalanceValue = Number(data?.prevalance);
        const prevalance =
          prevalanceValue >= 1 ? Math.round(prevalanceValue) : prevalanceValue;
        if (data?.displayType === "number") {
          if (data?.maxValue > 0 && Number(data?.prevalance) > 0) {
            if (data?.traitType && data?.value?.stringValue) {
              return data?.value?.stringValue;
            } else if (data?.traitType && data?.value?.floatValue) {
              return data?.value?.floatValue + " of " + data?.maxValue;
            } else if (data?.traitType && data?.value?.intValue) {
              return data?.value?.intValue + " of " + data?.maxValue;
            } else if (data?.traitType && data?.value?.boolValue) {
              return data?.value?.boolValue;
            }
          } else {
            return 0 + " of " + data?.maxValue;
          }
        } else {
          if (data?.prevalance === 0) {
            if (data?.value?.__typename === "AttributeValueString") {
              return data?.value?.stringValue;
            } else if (data?.value?.__typename === "AttributeValueFloat") {
              return data?.value?.floatValue + " of " + data?.maxValue;
            } else if (data?.traitType && data?.value?.intValue) {
              return data?.value?.intValue + " of " + data?.maxValue;
            } else if (data?.traitType && data?.value?.boolValue) {
              return data?.value?.boolValue;
            }
          } else {
            if (data?.value?.__typename === "AttributeValueString") {
              return prevalance + "% have this trait";
            } else if (data?.value?.__typename === "AttributeValueFloat") {
              return data?.value?.floatValue + " of " + data?.maxValue;
            }
          }
        }
      }
    };

    return (
      <Grid container spacing={1} {...props}>
        {displayTypeNotNumber?.map((ele: MetadataAttributes, index: number) => {
          return (
            <>
              <Grid key={index} item xs={6}>
                {
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    className="details-key">
                    {getProperties(ele)}
                  </Typography>
                }
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" className="details-obj-properties">
                  {getProperties(ele, false)}
                </Typography>
              </Grid>
            </>
          );
        })}
        {displayTypeNumber?.map((ele: MetadataAttributes, index: number) => {
          return (
            <>
              <Grid key={index} item xs={6}>
                {
                  <Typography
                    sx={{ textTransform: "capitalize" }}
                    className="details-key">
                    {getProperties(ele)}
                  </Typography>
                }
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" className="details-obj-properties">
                  {getProperties(ele, false)}
                </Typography>
              </Grid>
            </>
          );
        })}
        {displayTypeNotNumberWithZeroPrevalance?.map(
          (ele: MetadataAttributes) => {
            return (
              <>
                <Grid item xs={6}>
                  {
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      className="details-key">
                      {getProperties(ele)}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" className="details-obj-properties">
                    {getProperties(ele, false)}
                  </Typography>
                </Grid>
              </>
            );
          }
        )}
        {displayTypeNotNumberWithFloat?.map(
          (ele: MetadataAttributes, index: number) => {
            return (
              <>
                <Grid key={index} item xs={6}>
                  {
                    <Typography
                      sx={{ textTransform: "capitalize" }}
                      className="details-key">
                      {getProperties(ele)}
                    </Typography>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography align="right" className="details-obj-properties">
                    {getProperties(ele, false)}
                  </Typography>
                </Grid>
              </>
            );
          }
        )}
      </Grid>
    );
  }
)`
  .details-key {
    font-family: BentonSans;
    font-weight: 700;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      font-weight: 400;
    }
  }
  .details-obj-properties {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 12px;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const getPropertiesListdata = (
  NFTDetailsState: INFTDetails | null,
  NetworkList: [network] | null,
  isMobile: boolean,
  router: NextRouter,
  defaulttype = "",
  account: string,
  userName: string | undefined,
  avatar: string | undefined
): { [x: string]: any } => {
  const getPrice = () => {
    const status = getStatus(
      NFTDetailsState?.status ?? "",
      NFTDetailsState?.tokenOwnerAddress ?? "",
      account ?? "1"
    );
    if (NFTDetailsState) {
      if (
        getTokenAlignmentStatus(
          status,
          NFTDetailsState?.price,
          NFTDetailsState?.listedOrderInfo?.price
        )
      ) {
        if (status === "Make Offer") {
          if (
            NFTDetailsState?.price?.makeOfferHighestPrice &&
            NFTDetailsState?.price?.makeOfferHighestPrice?.[1]?.value > 0
          ) {
            return (
              <>
                {`${truncateDecimal(
                  NFTDetailsState?.price?.makeOfferHighestPrice?.[1]?.value,
                  4
                )} ${NFTDetailsState?.price?.makeOfferHighestPrice[1]?.unit}`}
                <span className="typo-body3 ms-2">
                  {`${formatNumberToUSD(
                    NFTDetailsState?.price?.makeOfferHighestPrice?.[0]?.value,
                    4
                  )} ${NFTDetailsState?.price?.makeOfferHighestPrice[0]?.unit}`}
                </span>
              </>
            );
          } else if (
            NFTDetailsState?.price?.lastPurchasedPrice &&
            NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value > 0
          ) {
            return (
              <>
                {`${truncateDecimal(
                  NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value,
                  4
                )} ${NFTDetailsState?.price?.lastPurchasedPrice[1]?.unit}`}
                <span className="typo-body3 ms-2">
                  {`${formatNumberToUSD(
                    NFTDetailsState?.price?.lastPurchasedPrice?.[0]?.value,
                    4
                  )} ${NFTDetailsState?.price?.lastPurchasedPrice[0]?.unit}`}
                </span>
              </>
            );
          } else {
            return "--";
          }
        } else if (status === "Buy Now" || status === "Edit Listing") {
          if (
            NFTDetailsState?.listedOrderInfo?.price &&
            NFTDetailsState?.listedOrderInfo?.price?.[1]?.value > 0
          ) {
            return (
              <>
                {`${truncateDecimal(
                  NFTDetailsState?.listedOrderInfo?.price?.[1]?.value,
                  4
                )} ${NFTDetailsState?.listedOrderInfo?.price[1]?.unit}`}
                <span className="typo-body3 ms-2">
                  {`${formatNumberToUSD(
                    NFTDetailsState?.listedOrderInfo?.price?.[0]?.value,
                    4
                  )} ${NFTDetailsState?.listedOrderInfo?.price[0]?.unit}`}
                </span>
              </>
            );
          } else if (
            NFTDetailsState?.price?.lastPurchasedPrice &&
            NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value > 0
          ) {
            return (
              <>
                {`${truncateDecimal(
                  NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value,
                  4
                )} ${NFTDetailsState?.price?.lastPurchasedPrice[1]?.unit}`}
                <span className="typo-body3 ms-2">
                  {`${formatNumberToUSD(
                    NFTDetailsState?.price?.lastPurchasedPrice?.[0]?.value,
                    4
                  )} ${NFTDetailsState?.price?.lastPurchasedPrice[0]?.unit}`}
                </span>
              </>
            );
          } else {
            return "--";
          }
        } else if (
          NFTDetailsState?.price?.lastPurchasedPrice &&
          NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value > 0
        ) {
          return (
            <>
              {`${truncateDecimal(
                NFTDetailsState?.price?.lastPurchasedPrice?.[1]?.value,
                4
              )} ${NFTDetailsState?.price?.lastPurchasedPrice[1]?.unit}`}
              <span className="typo-body3 ms-2">
                {`${formatNumberToUSD(
                  NFTDetailsState?.price?.lastPurchasedPrice?.[0]?.value,
                  4
                )} ${NFTDetailsState?.price?.lastPurchasedPrice[0]?.unit}`}
              </span>
            </>
          );
        } else if (
          NFTDetailsState?.price?.makeOfferHighestPrice &&
          NFTDetailsState?.price?.makeOfferHighestPrice?.[1]?.value > 0
        ) {
          return (
            <>
              {`${truncateDecimal(
                NFTDetailsState?.price?.makeOfferHighestPrice?.[1]?.value,
                4
              )} ${NFTDetailsState?.price?.makeOfferHighestPrice[1]?.unit}`}
              <span className="typo-body3 ms-2">
                {`${formatNumberToUSD(
                  NFTDetailsState?.price?.makeOfferHighestPrice?.[0]?.value,
                  4
                )} ${NFTDetailsState?.price?.makeOfferHighestPrice[0]?.unit}`}
              </span>
            </>
          );
        }
        {
          return "--";
        }
      }
    }
  };

  const getPriceLable = () => {
    const status = getStatus(
      NFTDetailsState?.status ?? "",
      NFTDetailsState?.tokenOwnerAddress ?? "",
      account ?? "1"
    );
    if (NFTDetailsState) {
      if (
        getTokenAlignmentStatus(
          status,
          NFTDetailsState?.price,
          NFTDetailsState?.listedOrderInfo?.price
        )
      ) {
        if (status === "Buy Now" || status === "Edit Listing") {
          if (
            NFTDetailsState?.listedOrderInfo?.price?.length > 0 ||
            NFTDetailsState?.price?.buyNowPrice?.length > 0
          ) {
            return status === "Edit Listing" ? "PRICE" : "LISTED PRICE";
          } else {
            return "PRICE";
          }
        } else if (status === "Make Offer") {
          if (NFTDetailsState?.price?.makeOfferHighestPrice?.length > 0) {
            return "HIGHEST OFFER";
          } else if (NFTDetailsState?.price?.lastPurchasedPrice?.length > 0) {
            return "LAST SALE";
          } else {
            return "PRICE";
          }
        } else if (NFTDetailsState?.price?.lastPurchasedPrice?.length > 0) {
          return "LAST SALE";
        } else if (NFTDetailsState?.price?.makeOfferHighestPrice?.length > 0) {
          return "HIGHEST OFFER";
        } else {
          return "PRICE";
        }
      } else {
        return "PRICE";
      }
    }
  };

  const data: { [k: string]: any } = {
    Network: (
      <>
        <Box className="network-box">{NFTDetailsState?.network ?? ""}</Box>
        {NFTDetailsState?.tokenType && (
          <Box className="token-type-box">
            <Typography align="center" className="token-type">
              {NFTDetailsState?.tokenType}
            </Typography>
          </Box>
        )}
      </>
    ),
    price: <>{getPrice()}</>,
    owner:
      !isEmpty(NFTDetailsState?.tokenOwnerUsername) ||
      !isEmpty(NFTDetailsState?.tokenOwnerAddress) ? (
        <>
          <span
            onClick={() => {
              if (!isEmpty(NFTDetailsState?.tokenOwnerUsername)) {
                router.push(
                  `/owner/${NFTDetailsState?.tokenOwnerAddress}`,
                  undefined,
                  { shallow: true }
                );
              } else {
                const url = CheckNetworkTokens(
                  NetworkList?.filter(
                    (ele) => ele?.id === NFTDetailsState?.networkID
                  )[0]?.chainID,
                  NFTDetailsState?.contractAddress,
                  NFTDetailsState?.tokenOwnerAddress
                );
                window.open(url, "_blank");
              }
            }}
            className={
              !isEmpty(NFTDetailsState?.tokenOwnerAddress)
                ? "details-obj cus-point"
                : "details-obj"
            }>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              {account === NFTDetailsState?.tokenOwnerAddress ? (
                <Avatar avatarKey={avatar} />
              ) : NFTDetailsState &&
                NFTDetailsState?.tokenOwnerAvatar?.length > 0 ? (
                <Avatar avatarKey={NFTDetailsState?.tokenOwnerAvatar} />
              ) : (
                <></>
              )}
              {NFTDetailsState?.tokenOwnerUsername === "zero_user"
                ? truncateAddress(NFTDetailsState?.tokenOwnerAddress)
                : account === NFTDetailsState?.tokenOwnerAddress
                ? userName
                : !isEmpty(NFTDetailsState?.tokenOwnerUsername)
                ? NFTDetailsState?.tokenOwnerUsername
                : truncateAddress(NFTDetailsState?.tokenOwnerAddress)}
            </Box>
          </span>
        </>
      ) : null,
  };
  if (defaulttype === "PRICE") {
    delete data.Network;
    delete data.owner;
  } else if (defaulttype === "NETWORK") {
    delete data.owner;
    delete data.price;
  } else if (!isMobile) {
    delete data.Network;
    delete data.price;
  }

  if (data?.price) {
    data[`${getPriceLable()}`] = data?.price;
    delete data["price"];
  }
  return {
    ...data,
  };
};

export const getContractTrails = (
  NFTDetailsState: INFTDetails | null,
  isMobile: boolean,
  NetworkList: [network] | null
): { [x: string]: any } => {
  const data: any = {
    "Network:": (
      <>
        <Box className="network-box">{NFTDetailsState?.network ?? ""}</Box>
        {NFTDetailsState?.tokenType && (
          <Box className="token-type-box">
            <Typography align="center" className="token-type">
              {NFTDetailsState?.tokenType}
            </Typography>
          </Box>
        )}
      </>
    ),
    "Contract address:": (
      <Typography
        className="contract"
        onClick={() => {
          const url = CheckNetworkTokens(
            NetworkList?.filter(
              (ele) => ele?.id === NFTDetailsState?.networkID
            )[0]?.chainID,
            NFTDetailsState?.contractAddress,
            String(NFTDetailsState?.tokenId)
          );
          window.open(url, "_blank");
          // copyToClipboard(NFTDetailsState?.contractAddress ?? "");
          // onHandleToast(true, "copied to clipboard", "success");
        }}>
        {truncateAddress(NFTDetailsState?.contractAddress)}
      </Typography>
    ),
    "Token ID:": (
      <Typography
        className={NFTDetailsState?.tokenId ? "contract" : ""}
        onClick={() => {
          if (NFTDetailsState?.tokenId && NFTDetailsState?.tokenURI?.length > 0)
            window.open(NFTDetailsState?.tokenURI, "_ blank");
        }}>
        {" "}
        {String(NFTDetailsState?.tokenId ?? "")?.length > 10
          ? truncateAddress(String(NFTDetailsState?.tokenId))
          : NFTDetailsState?.tokenId}{" "}
      </Typography>
    ),
    "EDITIONS:":
      NFTDetailsState?.tokenType === "ERC1155" && NFTDetailsState?.editions
        ? NFTDetailsState?.editionNumber
          ? `${NFTDetailsState?.editionNumber}/${NFTDetailsState?.editions}`
          : NFTDetailsState?.editions
        : null,
  };

  if (isMobile) {
    delete data["Network:"];
  }
  return {
    ...data,
  };
};
