import { IMojitoProfileCustomOrgs, IMojitoProfileUserOrg } from "@interfaces";
import { IwalletData } from "@layouts/profile/wallet/listitem";
import { getBuyNowOrMakeOfferPrice } from "./numberFormet.util";
import { IAllRegistryTokens, tokens } from "./wallet";

export const Filter: any[] = [
  { value: "price_low_to_high", lable: "Price Low to High" },
  { value: "price_high_to_low", lable: "Price High to Low" },
  { value: "recently_minted", lable: "Newest" },
  { value: "initially_minted", lable: "Oldest" },
  { value: "recently_listed", lable: "Recently Listed" },
];

export const FilterAdvanced: any[] = [
  { key: "price_high_to_low", sort: "price.buynowPrice.value", value: "desc", label: "Price High to Low" },
  { key: "price_low_to_high", sort: "price.buynowPrice.value", value: "asc", label: "Price Low to High" },
  { key: "recently_minted", sort: "createdAt", value: "desc", label: "Newest" },
  { key: "initially_minted", sort: "createdAt", value: "asc", label: "Oldest" },
];

export const loaderstyle = {
  width: "100%",
  marginTop: "5%",
  "@media screen and (max-width: 768px)": {
    marginTop: "25%",
  },
};

export const FilterWallet: any[] = [
  { value: "price_low_to_high", lable: "Price Low to High" },
  { value: "price_high_to_low", lable: "Price High to Low" },
  { value: "recently_minted", lable: "Newest" },
  { value: "initially_minted", lable: "Oldest" },
  { value: "recently_listed", lable: "Recently listed" },
  { value: "most_saved", lable: "Most saved" },
  {
    value: "purchased_from_org",
    lable: "Purchased on Sotheby’s Metaverse",
  },
];

export const getStatusButton = (status: string): string => {
  switch (status) {
    case "NEW":
      return "View NFT"; //Enquire to List
    case "ENQUIRY_PENDING":
      return "View NFT"; //Enquired
    case "OPEN_FOR_SALE":
      return "List Item";
    case "SALE_PENDING":
      return "Edit Listing";
    default:
      return "View NFT";
  }
};

export const getStatusOffer = (status: string): string => {
  switch (status) {
    case "OPEN_FOR_SALE":
      return "Make offer";
    case "SALE_PENDING":
      return "Buy now";
    default:
      return "";
  }
}

export const getKYCCheckBtn = (organization: (IMojitoProfileUserOrg & IMojitoProfileCustomOrgs) | null, status: string, isCancelOffer: boolean | null): string => {
  if (["List Item", "Edit Listing"].includes(status)) {
    if (organization?.isBlacklisted || !organization?.w8Form) {
      return "Incomplete Profile";
    } else {
      return status;
    }
  } else if (status === "Buy Now") {
    return status;
  } else if (status === "Make Offer") {
    if (isCancelOffer) {
      return "View Offer";
    } else {
      return status;
    }
  } else {
    return status;
  }
}

export const getWalletData = (data: IAllRegistryTokens, account: string): IwalletData => {
  return {
    contractName: data?.nftContractName,
    contractAddress: data?.Registry?.ContractAddress,
    nftTokenId: data?.NFTTokenID,
    tokenType: data?.TokenType,
    price: getBuyNowOrMakeOfferPrice(data?.Status, data?.Price, data?.listedOrderInfo?.price, data?.Owners, String(account)) ?? [],
    network: data?.networkName,
    id: String(data?.TokenID),
    artistName: data?.Registry?.Artist?.artistName,
    mintedAt: data?.MintedAt,
    metadata: { image: data?.MetaData?.image, name: data?.MetaData?.name, animationURL: data?.MetaData?.animationURL ?? "" },
    status: data?.Status,
    editionNumber: String(data?.editionNumber ?? ""),
    networkID: data?.networkID,
    tokenURI: data?.TokenURI,
    ownerAddress: data?.TokenOwnerAddress,
    mediaExtension: data?.mediaSourceExtension,
    mediaImage: data?.mediaSourceURL
  }
}

export const getWalletDataTokens = (data: tokens, networkId: string, account: string): IwalletData => {
  return {
    contractName: data?.contractName,
    contractAddress: data?.contractAddress,
    nftTokenId: data?.nftTokenId,
    tokenType: data?.tokenType,
    price: getBuyNowOrMakeOfferPrice(data?.status, data?.price, data?.listedOrderInfo?.price, data?.owner, String(account)) ?? [],
    network: data?.network,
    id: data?.id,
    artistName: data?.artistName,
    mintedAt: data?.mintedAt,
    metadata: { image: data?.metadata?.image, name: data?.metadata?.name, animationURL: data?.metadata?.animationURL ?? "" },
    status: data?.status,
    editionNumber: String(data?.editionNumber ?? ""),
    networkID: networkId,
    tokenURI: data?.tokenURI,
    ownerAddress: data?.owner ?? '',
    mediaExtension: data?.mediaSourceExtension,
    mediaImage: data?.mediaSourceURL
  }
}

export const CheckBuyMakeOffer = (status: string, isMakeOffer: boolean, isBuynow: boolean): boolean => {

  if (status === "Make Offer") {
    return !isMakeOffer;
  } else if (status === "Buy Now") {
    return !isBuynow;
  } else {
    return false;
  }

}
