import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  IMojitoProfile,
  IMojitoProfileCustomOrgs,
  IMojitoProfileUserOrg,
} from "@interfaces";
import {
  NftSaleModal,
  errorModalOpen,
  INFTListData,
} from "src/components/secondary-wallet/modal/secondary-wallet";
import {
  IEstamateTaxItems,
  NFTPrice,
  ISupportedCurrenciesList,
  network,
  ITaxResponse,
} from "@utils/wallet/wallet";
import { UseSecondaryWalletMojitoMutation } from "@hooks";
import {
  PDPURL,
  truncateDecimal,
  getPartyId,
} from "@utils/wallet/numberFormet.util";
import { useRouter } from "next/router";
import { EMojitoSecondaryWalletMutation } from "@state";
import {
  ApproveAll,
  CheckApproval,
  waitForApproval,
  getAccountProvider,
  getProviderType,
} from "@utils";
import { utils } from "ethers";
import moment from "moment";
import { EEventNames } from "@enums";
import {
  ContentType,
  DepartmentCode,
  listItemCompleted,
  listItemRemoved,
  listItemStarted,
  listItemStepCompleted,
  RejectedBy,
  StepName,
  transactionRejected,
} from "@analytics";

interface IListItem {
  nftModalOpen: boolean;
  profile: IMojitoProfile;
  address: `0x${string}` | undefined;
  WalletData: IwalletData | null;
  organization: (IMojitoProfileUserOrg & IMojitoProfileCustomOrgs) | null;
  supportedCurrencies: ISupportedCurrenciesList[];
  NetworkList: [network] | null;
  buttonLoader?: () => void;
  handleModelClose: () => void;
  refetchWalletContent: () => void;
}

interface removeListing {
  orgID: string;
  nftTokenId: number;
}

interface listItemForSale {
  orgId: string;
  nftTokenId: number;
  tokenType: string;
  quantity: number;
  nftOwnerAddress: string;
  fixedPrice: number;
  paymentToken: string;
}

interface submitApproval {
  nftTokenId: string;
  signature: string;
  creatorFee: number;
}

export interface IwalletData {
  contractName: string;
  contractAddress: string;
  nftTokenId: string;
  tokenType: string;
  price: NFTPrice[];
  network: string;
  id: string;
  artistName: string;
  mintedAt: string;
  metadata: { image: string; name: string; animationURL: string };
  status: string;
  editionNumber: string;
  networkID: string;
  tokenURI: string;
  ownerAddress: string;
  mediaImage?: string;
  mediaExtension?: string;
}

interface listItemTrackData {
  auth0_id: string;
  currency: string;
  price: number;
  product_id: string;
  product_name: string;
  product_type: string;
  token_type: string;
  eventName: string;
  quantity: number;
}

const ListItemComponent = ({
  nftModalOpen,
  organization,
  profile,
  address,
  WalletData,
  supportedCurrencies,
  NetworkList,
  handleModelClose,
  refetchWalletContent,
  buttonLoader,
}: IListItem): JSX.Element => {
  const router = useRouter();
  const auth0_id = profile?.userOrgs[0]?.externalUserId || "";
  const { getIdTokenClaims } = useAuth0();
  const [listModelStatus, setListmodelStatus] = useState<boolean>(false);

  const [nftData, setNftData] = useState<INFTListData>({
    imgUrl: "",
    animatedUrl: "",
    networkId: "",
    networKTokenType: "",
    contractAddress: "",
    id: "",
    nftTokenId: "",
    network: "",
    currency: "",
    networkTokenType: "",
    tokenId: "",
    tokenType: "",
    artistName: "",
    name: "",
    year: "",
    editionNumber: "",
    tokenURI: "",
    mediaExtension: "",
    mediaImage: "",
  });

  const [estimateTax, setEstimateTax] = useState<IEstamateTaxItems | any>({
    taxPercentage: 0,
    royaltyFee: 0,
    platformFee: 0,
    taxResponse: {
      cryptoTaxPrice: 0,
      cryptoTotalPrice: 0,
      USDUnitprice: 0,
      taxPercentage: 0,
    },
  });

  const [toastCheckToken, setToastCheckToken] = useState<errorModalOpen>({
    open: false,
    msg: "",
  });

  const [nftModalButtonLoader, setNftModalButtonLoader] = useState<any>({
    name: "",
  });

  const [nftModalType, setNftModalType] = useState<string>("SALE");
  const [nftEditType, setNftEditType] = useState<boolean>(false);
  const [listItemFlowMsg, setListingFlowMsg] = useState<string>("");
  const [nftPrcieVal, setNftPriceVal] = useState<NFTPrice[]>([]);

  const [removeListing] = UseSecondaryWalletMojitoMutation<removeListing>(
    EMojitoSecondaryWalletMutation.removeListing
  );

  const [createListItemForSale] =
    UseSecondaryWalletMojitoMutation<listItemForSale>(
      EMojitoSecondaryWalletMutation.createListItemForSale
    );

  const [submitApproval] = UseSecondaryWalletMojitoMutation<submitApproval>(
    EMojitoSecondaryWalletMutation.submitProofApproval
  );

  const [estimateTaxAndRoyaltyFee] = UseSecondaryWalletMojitoMutation<{
    taxPercentage: string;
    royaltyFee: string;
    platformFee: string;
    taxResponse: ITaxResponse;
  }>(EMojitoSecondaryWalletMutation.estimateTaxAndRoyaltyFee);

  useEffect(() => {
    if (nftModalOpen && WalletData) {
      onClickItem(WalletData);
    }
  }, [nftModalOpen]);

  const handleModelCloseNFT = () => {
    handleModelClose();
    setListmodelStatus(false);
  };

  const getFeesData = (wallet: IwalletData) => {
    estimateTaxAndRoyaltyFee({
      orgId: profile?.userOrgs?.[0]?.organizationId,
      nftTokenId: wallet?.nftTokenId,
      price: 1,
      estimateType: "ListForSale",
    }).then((res: any) => {
      setEstimateTax({
        platformFee: res?.estimateTaxAndRoyaltyFee?.platformFee,
        royaltyFee: res?.estimateTaxAndRoyaltyFee?.royaltyFee,
        taxPercentage: res?.estimateTaxAndRoyaltyFee?.taxPercentage,
        taxResponse: {
          ...res?.estimateTaxAndRoyaltyFee?.taxResponse,
        },
      });
      if (wallet?.price) {
        setNftData({
          ...nftData,
          imgUrl: wallet?.metadata?.image,
          animatedUrl: wallet?.metadata?.animationURL
            ? wallet?.metadata?.animationURL
            : "",
          networkId: wallet?.network ?? "",
          networkTokenType: wallet?.tokenType,
          contractAddress: wallet?.contractAddress,
          id: wallet?.id,
          nftTokenId: wallet?.nftTokenId,
          network: wallet?.network,
          currency: wallet?.price[1]?.unit,
          tokenId: wallet?.id,
          artistName: wallet?.artistName,
          name: wallet?.metadata?.name ? wallet?.metadata?.name : "",
          year: wallet?.mintedAt
            ? moment(wallet?.mintedAt).format("YYYY")
            : "-",
          tokenURI: wallet?.tokenURI,
          mediaExtension: wallet?.mediaExtension ?? "",
          mediaImage: wallet?.mediaImage ?? "",
        });
        setNftPriceVal(wallet?.price);
        setListmodelStatus(true);
      }
    });
  };

  const onClickItem = (wallet: IwalletData) => {
    if (["NEW", "ENQUIRY_PENDING"].includes(wallet?.status)) {
      router.push(
        PDPURL(
          wallet?.contractAddress,
          wallet?.id,
          wallet?.tokenType,
          address ?? ""
        ),
        undefined,
        {
          shallow: true,
        }
      );
    }
    if (wallet?.status === "OPEN_FOR_SALE") {
      setNftModalType("SALE");
      setNftEditType(false);
      getFeesData(wallet);
    }
    if (wallet?.status === "SALE_PENDING") {
      setNftModalType("EDIT");
      setNftEditType(true);
      getFeesData(wallet);
    }
  };

  const listItemTrack = (listItemTrackData: listItemTrackData) => {
    setTimeout(async () => {
      const partyId = await getPartyId(getIdTokenClaims);
      if (listItemTrackData.eventName === EEventNames.listItemStarted) {
        listItemStarted({
          ...listItemTrackData,
          creator: address ?? "",
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          department_category: "secondary",
          department_code: DepartmentCode?.Enft,
        });
      } else if (
        listItemTrackData.eventName === EEventNames.listItemApprovalCompleted ||
        listItemTrackData.eventName === EEventNames.listItemMetaMaskCompleted
      ) {
        listItemStepCompleted({
          ...listItemTrackData,
          creator: address ?? "",
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          department_category: "secondary",
          department_code: DepartmentCode?.Enft,
          step:
            listItemTrackData.eventName ===
            EEventNames.listItemApprovalCompleted
              ? 1
              : 2,
          step_name: StepName.Submit,
        });
      } else if (listItemTrackData.eventName === EEventNames.removeListItem) {
        listItemRemoved({
          ...listItemTrackData,
          creator: address ?? "",
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          department_category: "secondary",
          department_code: DepartmentCode?.Enft,
        });
      } else if (
        listItemTrackData.eventName === EEventNames.listItemCompleted
      ) {
        listItemCompleted({
          ...listItemTrackData,
          creator: address ?? "",
          content_type: ContentType.MetaverseMarket,
          customer_id: partyId,
          department_category: "secondary",
          department_code: DepartmentCode?.Enft,
        });
      }
    }, 1000);
  };

  const submitApprovalListing = (
    tokenId: string,
    message: string,
    wallet: any,
    fixedPrice: number,
    selectedCurrency: string,
    currencyId: string,
    paymentToken: string
  ) => {
    submitApproval({
      orgID: profile?.userOrgs[0]?.organizationId,
      nftTokenId: tokenId,
      signature: message,
      quantity: 1,
      fixedPrice: fixedPrice,
      paymentToken: paymentToken,
      currencyId: currencyId,
      creatorFee: +truncateDecimal(
        fixedPrice * (estimateTax?.royaltyFee / 100),
        4
      ),
    })
      .then(() => {
        listItemTrack({
          price: fixedPrice,
          currency: selectedCurrency,
          product_id: tokenId,
          token_type: wallet?.tokenType,
          auth0_id: auth0_id,
          product_name: wallet?.metadata?.name,
          product_type: wallet?.tokenType,
          quantity: 1,
          eventName: EEventNames.listItemCompleted,
        });
        setNftModalType("SALE_SUCCESS");
        setNftModalButtonLoader({
          name: "",
        });
      })
      .catch(() => {
        setNftModalType("SALE_FAILED");
      });
  };

  const approvalFlow = (
    wallet: any,
    fixedPrice: any,
    selectedCurrency: any
  ) => {
    createListItemForSale({
      orgId: profile?.userOrgs[0]?.organizationId,
      nftTokenId: wallet?.nftTokenId,
      tokenType: wallet?.tokenType,
      quantity: 1,
      nftOwnerAddress: address,
      fixedPrice: fixedPrice,
      paymentToken: selectedCurrency?.contractAddress ?? "",
      currencyId: selectedCurrency?.id ?? "",
    })
      .then(async (res: any) => {
        if (res) {
          setNftModalType("SALE_PENDING");
          setListingFlowMsg(
            "Item listing in progress. Follow wallet signing prompts to complete the listing..."
          );

          try {
            const type = getProviderType();
            const provider = await getAccountProvider(type);
            const signer = provider.getSigner();
            const messageToSign = utils.arrayify(
              res?.createSignatureToListNFTForSale?.messageToSign
            );
            const signature = await signer?.signMessage(messageToSign);
            listItemTrack({
              price: +fixedPrice,
              currency: selectedCurrency,
              product_id: wallet?.nftTokenId,
              token_type: wallet?.tokenType,
              auth0_id: auth0_id,
              product_name: wallet?.metadata?.name,
              product_type: wallet?.tokenType,
              quantity: 1,
              eventName: EEventNames.listItemMetaMaskCompleted,
            });
            submitApprovalListing(
              wallet?.nftTokenId,
              signature,
              wallet,
              fixedPrice,
              selectedCurrency,
              selectedCurrency?.id,
              selectedCurrency?.contractAddress
            );
          } catch (e: any) {
            if (e) {
              setNftModalType("SALE_FAILED");
              if (
                e.message?.includes("user rejected transaction") ||
                e.message?.includes("User denied transaction signature") ||
                e.message?.includes("user rejected signing")
              ) {
                const partyId = await getPartyId(getIdTokenClaims);
                transactionRejected({
                  auth0_id: auth0_id,
                  creator: wallet?.nftOwnerAddress,
                  customer_id: partyId,
                  department_category: "Secondary MarketPlace",
                  price: +fixedPrice,
                  product_id: wallet?.nftTokenId ?? "",
                  rejected_by: RejectedBy.Buyer,
                  transaction_type: "crypto",
                });
              }
            }
          }
        }
      })
      .catch((e) => {
        if (e && e?.message) {
          setToastCheckToken({
            open: true,
            msg: e?.message,
          });
          setNftModalButtonLoader({
            name: "",
          });
          buttonLoader?.();
        }
      });
  };

  return (
    <>
      {listModelStatus && supportedCurrencies && estimateTax && (
        <NftSaleModal
          modelopen={listModelStatus}
          isEdit={nftEditType}
          type={nftModalType}
          organization={organization}
          priceVal={nftPrcieVal}
          nftData={nftData}
          isValidNetwork={
            NetworkList?.filter((ele) => ele?.name === nftData?.networkId)[0]
              ?.chainID
          }
          listingFlowMsg={listItemFlowMsg}
          toastErrorModalOpen={toastCheckToken}
          buttonLoader={nftModalButtonLoader}
          twitterUrl={PDPURL(
            nftData?.contractAddress,
            nftData?.tokenId,
            nftData?.networkTokenType,
            address ?? ""
          )}
          supportedCurrencies={supportedCurrencies}
          estimateTax={estimateTax}
          toastErrorModalClose={() =>
            setToastCheckToken({
              open: false,
              msg: "",
            })
          }
          handleClose={() => handleModelCloseNFT()}
          handleClick={async (
            fixedPrice: string,
            btnName: string,
            selectedCurrency: any
          ) => {
            // default setstate
            setToastCheckToken({
              open: false,
              msg: "",
            });
            setNftModalButtonLoader({
              name: "",
            });
            // based on button name - to click event will call

            // retry - failed metamsk event
            if (btnName === "retry") {
              if (nftEditType) {
                setNftModalType("EDIT");
              } else {
                setNftModalType("SALE");
              }
            }
            // click on goBack or cancel
            if (btnName === "cancel") {
              buttonLoader?.();
              refetchWalletContent();
              handleModelCloseNFT();
            }
            // Success event
            if (btnName === "success") {
              buttonLoader?.();
              handleModelCloseNFT();
              refetchWalletContent();
            }

            if (WalletData) {
              const wallet = WalletData;
              //remove listing
              if (btnName === "removeListing") {
                setNftModalButtonLoader({
                  name: "removeListing",
                });
                removeListing({
                  orgID: profile?.userOrgs[0]?.organizationId,
                  nftTokenId: wallet.nftTokenId,
                }).then((res) => {
                  if (res) {
                    listItemTrack({
                      price: +fixedPrice,
                      currency: selectedCurrency,
                      product_id: wallet?.nftTokenId,
                      token_type: wallet?.tokenType,
                      auth0_id: auth0_id,
                      product_name: wallet?.metadata?.name,
                      product_type: wallet?.tokenType,
                      quantity: 1,
                      eventName: EEventNames.removeListItem,
                    });
                    buttonLoader?.();
                    handleModelCloseNFT();
                    setNftModalButtonLoader({
                      name: "",
                    });
                    refetchWalletContent();
                  }
                });
              }
              // redirect link
              if (btnName === "redirectSothebys") {
                router.push(
                  PDPURL(
                    nftData?.contractAddress,
                    nftData?.tokenId,
                    nftData?.networkTokenType,
                    address ?? ""
                  ),
                  undefined,
                  {
                    shallow: true,
                  }
                );
              }
              // open for sale and edit for sale
              if (btnName === "Open_for_sale" || btnName === "Edit_for_sale") {
                setNftModalButtonLoader({
                  name: "saleListing",
                });
                if (wallet) {
                  if (wallet?.nftTokenId !== null) {
                    try {
                      listItemTrack({
                        price: +fixedPrice,
                        currency: selectedCurrency,
                        product_id: wallet?.nftTokenId,
                        token_type: wallet?.tokenType,
                        auth0_id: auth0_id,
                        product_name: wallet?.metadata?.name,
                        product_type: wallet?.tokenType,
                        quantity: 1,
                        eventName: EEventNames.listItemStarted,
                      });
                      const approval = await CheckApproval(
                        selectedCurrency?.marketPlaceContractAddress,
                        wallet.contractAddress,
                        wallet.contractName
                      );

                      if (approval) {
                        approvalFlow(wallet, fixedPrice, selectedCurrency);
                      } else {
                        setNftModalType("SALE_PENDING");
                        setListingFlowMsg(
                          "Your NFT needs an approval. Please follow wallet prompt to proceed."
                        );

                        const transaction = await ApproveAll(
                          selectedCurrency?.marketPlaceContractAddress,
                          wallet.contractAddress,
                          wallet.contractName
                        );
                        if (transaction.hash) {
                          setListingFlowMsg(
                            "Your approval is in progress. Please wait for transaction completion."
                          );
                        }
                        const approveAll = await waitForApproval(transaction);
                        // list Item Approval Flow Completed
                        listItemTrack({
                          price: +fixedPrice,
                          currency: selectedCurrency,
                          product_id: wallet?.nftTokenId,
                          token_type: wallet?.tokenType,
                          auth0_id: auth0_id,
                          product_name: wallet?.metadata?.name,
                          product_type: wallet?.tokenType,
                          quantity: 1,
                          eventName: EEventNames.listItemApprovalCompleted,
                        });
                        if (approveAll) {
                          approvalFlow(wallet, fixedPrice, selectedCurrency);
                        } else {
                          if (btnName === "Open_for_sale") {
                            setNftModalType("SALE");
                          } else {
                            setNftModalType("EDIT");
                          }

                          setNftModalButtonLoader({
                            open: false,
                            name: "",
                          });

                          setToastCheckToken({
                            open: true,
                            msg: "Your approval is cancelled by the user. Please try again.",
                          });
                        }
                      }
                    } catch (e: any) {
                      setNftModalType("SALE_FAILED");
                    }
                  } else {
                    setNftModalButtonLoader({
                      name: "",
                    });
                    setToastCheckToken({
                      open: true,
                      msg: "Unable to list item for sale. Please try again or contact support.",
                    });
                  }
                }
              }
            }
          }}
        />
      )}
    </>
  );
};

export default ListItemComponent;
