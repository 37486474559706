import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { EViewSize } from "@enums";
import CloseIcon from "@mui/icons-material/Close";
import SelectDropdown from "src/components/secondary-wallet/Select-dropdown";
import moment from "moment";
import {
  isEmpty,
  truncateAddress,
  truncateDecimal,
  formatNumberToUSD,
} from "@utils/wallet/numberFormet.util";
import {
  network,
  NFTPrice,
  getTaxQuote,
  IGetTax,
  ITaxResponse,
} from "@utils/wallet/wallet";
import { ConnectType } from "@utils/wallet/connectContext";
import {
  INotificationByType,
  notificationsByType,
  useOrganization,
} from "@hooks";
import { SecondaryButton } from "src/components/secondary-wallet/secondaryButton";
import { NFTPreview } from "@layouts/details/details_component";
import { palette } from "@styles/theme";
import {
  getProviderType,
  getAccountProvider,
  CheckNetworkTokens,
} from "@utils";
import { LOCAL_STORAGE_STATE } from "@utils/wallet/clearLocalStorage";
import CountryStateSelector, {
  Ivalues,
} from "../countrySelector/countryselect";
import postalCodes from "postal-codes-js";
import { IMojitoProfileUserOrg } from "@interfaces";

const style = {
  position: "absolute",
  left: "50%",
  top: "2%",
  transform: "translate(-50%, 4%)",
  "@media screen and (max-width: 768px)": {
    width: "95%",
    overflow: "auto",
    padding: "20px",
  },
  width: "940px",
  backgroundColor: palette.white,
  boxShadow: 24,
  padding: "32px 32px 16px 32px",
  borderRadius: "5px",
};

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-block;
  width: 15px;
  height: 15px;
  border: solid 1px black;
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  margin-bottom: -2px;
  margin-right: 4px;

  &::after {
    content: "";
    width: 7px;
    height: 7px;
    border-radius: 4px;
    background: ${({ checked }) => (checked ? "black" : "transparent")};
    display: block;
    margin: 3px;
    transition: all 150ms;
  }
`;

export interface CheckboxProps {
  className?: string;
  checked?: boolean;
  onChange?: React.ComponentProps<typeof HiddenCheckbox>["onChange"];
}

export const CheckboxBase: React.FC<CheckboxProps> = ({
  className,
  checked,
  ...rest
}) => {
  return (
    <label className={className}>
      <HiddenCheckbox checked={checked} {...rest} />
      <StyledCheckbox checked={checked} />
    </label>
  );
};

export const Checkbox = styled(CheckboxBase)`
  display: inline-block;
  vertical-align: middle;
`;

export interface IAddress {
  country: { lable: string; value: string };
  postalCode: string;
  state: string;
  city: string;
  street: string;
}

export interface selectedCurrencyProps {
  contractAddress: string;
  currency: string;
  id: string;
  unit: string;
  marketPlaceContractAddress: string;
}

interface IMakeOffer {
  makeOfferModel: boolean;
  image: string;
  NftNetworkDetails: network;
  NFTDetailsState: {
    orderId: string;
    nftTokenId: string;
    latestOffer: {
      price: NFTPrice[];
    };
    price: NFTPrice[];
    tokenId: string;
    contractAddress: string;
    name: string;
    artistName: string;
    year: string;
    chainId: number;
    tokenURI: string;
    animationURL?: string;
    mediaImage: string;
    mediaExtension: string;
  };
  connectMetaMask: ConnectType;
  onChangeCurrency: (currency: string) => void;
  onClickMakeOffer: (
    crypto: number,
    usd: number,
    date: string,
    address: IAddress,
    selectedCurrency: selectedCurrencyProps,
    taxResponseInput: ITaxResponse | null
  ) => void;
  onClickBuynow: (
    selectedCurrency: selectedCurrencyProps,
    address: IAddress,
    taxResponseInput: ITaxResponse | null
  ) => void;
  onHandleClose: () => void;
  handleSwapAmount: (
    val: number,
    taxPercentage: number,
    usdAmount: number,
    date: string,
    address: IAddress,
    selectedCurrency: selectedCurrencyProps,
    taxResponseInput: ITaxResponse | null
  ) => void;
  handleSwitchNetwork: () => void;
  isBuy: boolean;
  supportedCurrencies?: any;
  cryptoBID: number;
  orgId: string;
}

interface IfeesValue {
  usd: string;
  crypto: string;
  usdName?: string;
  cryptoName?: string;
}

const summaryStyle = {
  warningContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    backgroundColor: palette.warningBackground,
    marginBottom: 10,
    marginTop: 15,
    borderRadius: 5,
    borderColor: palette.warning,
    borderWidth: 1,
    border: "1px solid #BD2200",
  },
  underlineStyle: {
    fontSize: 12,
    color: palette.warning,
    textDecorationLine: "underline",
    marginLeft: 3,
    cursor: "pointer",
  },
  warningText: { fontSize: 12, color: palette.warning },
};

export const BuyNowMakeOfferModal = styled(
  ({
    makeOfferModel,
    image,
    NftNetworkDetails,
    NFTDetailsState,
    connectMetaMask,
    cryptoBID,
    onClickMakeOffer,
    onClickBuynow,
    onHandleClose,
    handleSwapAmount,
    handleSwitchNetwork,
    isBuy,
    supportedCurrencies,
    orgId,
    onChangeCurrency,
    ...props
  }: IMakeOffer) => {
    const { organization } = useOrganization();
    const inputRef: any = useRef(null);
    const zipcodeRef: any = useRef(null);

    const userAddressDetails: any = window.localStorage.getItem(
      LOCAL_STORAGE_STATE?.secondary_wallet?.address_details
    );
    const userAddressData = JSON.parse(userAddressDetails);

    const getStateData = () => {
      if (userAddressData?.state) {
        return userAddressData?.state;
      } else {
        return { lable: "", value: "" };
      }
    };

    const [kycStatusVal, setKycStatusVal] = useState<boolean>(false);
    const [contactUs, setContactUs] = useState<boolean>(false);
    const [isNativeCurrency, setIsNativeCurrency] = useState<boolean>(
      isBuy ? true : false
    );
    const Filter: any[] = [
      { value: "1", lable: "1 Day" },
      { value: "2", lable: "2 Days" },
      { value: "3", lable: "3 Days" },
      { value: "7", lable: "7 Days" },
      { value: "30", lable: "30 Days" },
      // { value: "Custom", lable: "Custom" },
    ];
    const currencyList = supportedCurrencies
      ?.filter(
        (ele: any) =>
          isBuy || (!isBuy && ["WMATIC", "WETH"].includes(ele?.symbol))
      )
      ?.map((item: any) => {
        return {
          value: item?.id,
          lable: isBuy
            ? NFTDetailsState?.price[1]?.value + " " + item?.symbol
            : item?.symbol,
          id: item?.contractAddress,
          unit: item?.symbol,
          marketPlaceContractAddress: item?.secondaryMarketplaceContractAddress,
        };
      });
    const PriceList = currencyList?.sort((a: any, b: any) =>
      a?.unit?.localeCompare(b?.unit)
    );

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [FilterData, setFilterData] = useState<string>("1");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedCurrency, setSelectedCurrency] =
      useState<selectedCurrencyProps>({
        contractAddress: PriceList?.[0]?.id ?? "",
        currency: PriceList?.[0]?.lable ?? "",
        id: PriceList?.[0]?.value ?? "",
        unit: PriceList?.[0]?.unit ?? "",
        marketPlaceContractAddress: PriceList?.[0]?.marketPlaceContractAddress,
      });

    const [Address, setAddress] = useState({
      street: userAddressData?.street ?? "",
      country: userAddressData?.country ?? { lable: "", value: "" },
      city: userAddressData?.city ?? "",
      state: getStateData(),
      postalCode: userAddressData?.postalCode ?? "",
    });
    const [date, setDate] = useState<string>(
      moment(moment()).add(+FilterData, "days").format("MMMM DD, YYYY hh:mm A")
    );
    const [networkValidation, setNetworkValidation] = useState({
      status: "",
      message: "",
    });
    const [usdPrice, setUsdPrice] = useState<IfeesValue>({
      usd: "",
      crypto: "",
      usdName: "",
      cryptoName: "",
    });
    const [cryptoTaxValue, setCryptoTaxValue] = useState<number>(0);
    const [currencyCode, setCurrencyCode] = useState<string>("");
    const [isMakeOfferState, setIsMakeOfferState] = useState(false);
    const [isSwap, setIsSwap] = useState(false);

    const { getTaxData } = getTaxQuote({
      city: Address?.city,
      country: Address?.country?.value,
      currencyCode: currencyCode,
      orgID: orgId,
      postalCode: Address?.postalCode,
      state: Address?.state?.lable,
      street1: Address?.street,
      taxablePrice: isBuy ? NFTDetailsState?.price[0]?.value : +usdPrice?.usd,
    });

    const currentNotificationKey = Object.keys(
      organization?.notifications ?? []
    ).find(
      (key) =>
        organization?.notifications?.[
          key as keyof IMojitoProfileUserOrg["notifications"]
        ]
    ) as keyof INotificationByType;

    const userRoleContext = notificationsByType[currentNotificationKey];

    useEffect(() => {
      onChangeCurrency(selectedCurrency?.unit);
    }, [selectedCurrency]);

    useEffect(() => {
      inputRef?.current?.focus();
      getNetworkDetails();
      if (usdPrice.crypto) {
        onChangeCrypto(usdPrice.crypto);
      }
    }, [connectMetaMask]);

    useEffect(() => {
      if (getTaxData && isMakeOfferState && isBuy && !isSwap) {
        handleBuyNow(getTaxData);
      } else if (getTaxData && isMakeOfferState && !isSwap) {
        handleMakeOffer(getTaxData);
      } else if (getTaxData && isSwap) {
        handleSwap(getTaxData);
      }
    }, [getTaxData]);

    const getNetworkDetails = async () => {
      const providerType = getProviderType();
      const provider = await getAccountProvider(providerType);
      const { chainId } = await provider.getNetwork();
      if (+NftNetworkDetails?.chainID != +chainId) {
        setNetworkValidation({ status: "NETWORK", message: "" });
        return true;
      } else {
        setNetworkValidation({ status: "", message: "" });
        return false;
      }
    };

    const onChangeDate = (
      date: string,
      key: string,
      id: string,
      unit: string,
      type: string,
      secondaryMarketplaceContractAddress: string
    ) => {
      if (type === "currency") {
        setSelectedCurrency({
          contractAddress: id,
          currency: key,
          id: date,
          unit: unit,
          marketPlaceContractAddress: secondaryMarketplaceContractAddress,
        });
        if (unit?.toUpperCase() === "ETH" || unit.toUpperCase() === "MATIC") {
          setIsNativeCurrency(true);
        } else {
          setIsNativeCurrency(false);
        }
        onChangeCrypto(usdPrice?.crypto);
      } else {
        setFilterData(date);
        const new_date = moment(moment()).add(+date, "days");
        setDate(new_date.format("MMMM DD, YYYY hh:mm A"));
      }
    };

    const onChangeCrypto = (val: string) => {
      if (networkValidation?.status !== "NETWORK") {
        setNetworkValidation({
          status: "",
          message: "",
        });
      }
      setUsdPrice({
        ...usdPrice,
        crypto: val,
        usd: truncateDecimal(String(+val * cryptoBID)),
      });
      const usdVal: any = truncateDecimal(String(+val * cryptoBID));
      if (usdVal > 10000) {
        if (
          organization?.role === "Basic" ||
          organization?.role === "MissingInformation" ||
          organization?.role === "EndUser" ||
          organization?.role === "TransactionalNoID"
        ) {
          setKycStatusVal(true);
          setContactUs(false);
        } else {
          setKycStatusVal(false);
          setContactUs(false);
        }
      } else {
        setKycStatusVal(false);
        setContactUs(false);
      }
    };

    const dropDownDataView = () => {
      return (
        Filter &&
        Filter?.filter((ele) => ele?.value !== FilterData)?.map((ele: any) => (
          <Typography
            className={" menu-item my-2"}
            key={ele?.value}
            onClick={() => {
              onChangeDate(ele?.value, ele?.lable, "", "", "makeOffer", "");
            }}>
            {ele?.lable}
          </Typography>
        ))
      );
    };
    const dropDownPrice = () => {
      return (
        PriceList &&
        PriceList?.filter(
          (ele: any) => ele?.value !== selectedCurrency?.id
        )?.map((ele: any) => (
          <Typography
            className={" menu-item my-2"}
            key={ele?.value}
            onClick={() => {
              onChangeDate(
                ele?.value,
                ele?.lable,
                ele?.id,
                ele?.unit,
                "currency",
                ele?.marketPlaceContractAddress
              );
            }}>
            {ele?.lable}
          </Typography>
        ))
      );
    };

    const onChangeState = (lable: string, value: Ivalues) => {
      setNetworkValidation({
        message: "",
        status: "",
      });
      setAddress({
        ...Address,
        state: value,
      });
    };

    const onChangeCountry = (lable: string, value: Ivalues) => {
      setNetworkValidation({
        message: "",
        status: "",
      });
      setAddress({
        ...Address,
        country: value,
        postalCode: "",
        state: { lable: "", value: "" },
        city: "",
      });
    };

    const onChangeCity = (e: any) => {
      setAddress({
        ...Address,
        city: e.target.value,
      });
    };
    const onChangeStreet = (e: any) => {
      setAddress({
        ...Address,
        street: e.target.value,
      });
    };

    const checkNetworkAndBalance = (price: number, taxPercent: number) => {
      const totalAmount = price + taxPercent;
      const swapAmount =
        totalAmount - Number(connectMetaMask?.wethOrWmaticBalance);
      if (
        isNativeCurrency &&
        truncateDecimal(Number(connectMetaMask?.ethOrMaticBalance), 4) <
          truncateDecimal(totalAmount, 4)
      ) {
        setNetworkValidation({
          status: "NO_BALANCE",
          message: `Insufficient Balance. Please deposit ${price} ${selectedCurrency?.unit} (including tax ${taxPercent} ${selectedCurrency?.unit}) on your wallet and try again.`,
        });
        return false;
      } else if (
        !isNativeCurrency &&
        truncateDecimal(Number(connectMetaMask?.wethOrWmaticBalance), 4) <
          truncateDecimal(totalAmount, 4)
      ) {
        if (
          truncateDecimal(Number(connectMetaMask?.ethOrMaticBalance), 4) >=
          truncateDecimal(swapAmount, 4)
        ) {
          setNetworkValidation({
            status: "SWAP",
            message: `Would you like to swap ${truncateDecimal(
              swapAmount,
              4
            )} from ${
              selectedCurrency?.unit === "WMATIC" ? "MATIC" : "ETH"
            } to ${
              selectedCurrency?.unit === "WMATIC" ? "WMATIC" : "WETH"
            } (including tax ${taxPercent} ${selectedCurrency?.unit})?`,
          });
          return false;
        } else {
          setNetworkValidation({
            status: "NO_BALANCE",
            message: `Insufficient Balance. Please deposit ${price} ${selectedCurrency?.unit} (including tax ${taxPercent} ${selectedCurrency?.unit}) on your wallet and try again.`,
          });
          return false;
        }
      } else {
        setNetworkValidation({
          status: "",
          message: "",
        });
        return true;
      }
    };

    const handleBuyNow = async (taxData: IGetTax) => {
      setIsMakeOfferState(false);
      setIsSwap(false);
      setIsLoading(true);
      setNetworkValidation({ status: "", message: "" });
      localStorage.setItem(
        LOCAL_STORAGE_STATE?.secondary_wallet?.address_details,
        JSON.stringify(Address)
      );
      if (isBuy) {
        if (taxData) {
          setCurrencyCode("");
          setIsLoading(false);
          const taxPrice = taxData?.totalTaxAmount / cryptoBID;
          const cryptoTaxConvertion = +truncateDecimal(taxPrice, 4);
          const cryptoTotalPrice = +truncateDecimal(
            +NFTDetailsState?.price[1]?.value + cryptoTaxConvertion,
            4
          );
          const TaxPercent =
            ((getTaxData?.totalTaxAmount ?? 0) /
              (getTaxData?.totalTaxedPrice ?? 0)) *
            100;
          setCryptoTaxValue(cryptoTaxConvertion);
          if (
            isEmpty(networkValidation?.status) &&
            Address?.postalCode.length >= 5
          ) {
            const providerType = getProviderType();
            const provider = await getAccountProvider(providerType);
            const { chainId } = await provider.getNetwork();
            if (NftNetworkDetails?.chainID != +chainId) {
              setNetworkValidation({
                status: "NETWORK",
                message: "",
              });
            } else if (
              checkNetworkAndBalance(
                +NFTDetailsState?.price[1]?.value,
                cryptoTaxConvertion
              )
            ) {
              onClickBuynow(
                selectedCurrency,
                {
                  ...Address,
                  state: Address?.state?.lable,
                },
                {
                  cryptoTaxPrice: +cryptoTaxConvertion,
                  cryptoTotalPrice: cryptoTotalPrice,
                  USDUnitprice: cryptoBID,
                  taxPercentage: +truncateDecimal(TaxPercent, 4),
                }
              );
            }
          }
        }
      }
    };

    const handleMakeOffer = (taxData: IGetTax) => {
      setIsMakeOfferState(false);
      setIsSwap(false);
      setIsLoading(true);
      setNetworkValidation({ status: "", message: "" });
      localStorage.setItem(
        LOCAL_STORAGE_STATE?.secondary_wallet?.address_details,
        JSON.stringify(Address)
      );
      if (taxData) {
        setCurrencyCode("");
        const taxPrice = taxData?.totalTaxAmount / cryptoBID;
        const cryptoTaxConvertion = +truncateDecimal(taxPrice, 4);
        const cryptoTotalPrice = +truncateDecimal(
          +usdPrice?.crypto + cryptoTaxConvertion,
          4
        );
        const TaxPercent =
          ((getTaxData?.totalTaxAmount ?? 0) /
            (getTaxData?.totalTaxedPrice ?? 0)) *
          100;
        setCryptoTaxValue(cryptoTaxConvertion);
        setIsLoading(false);
        if (!kycStatusVal || !contactUs) {
          if (
            !isEmpty(date) &&
            !isNaN(+usdPrice?.crypto) &&
            +usdPrice?.crypto >= 0.04 &&
            networkValidation?.status === "" &&
            Address?.postalCode.length >= 5 &&
            Address?.city.length >= 0 &&
            Address?.street.length >= 0 &&
            Address?.country?.value.length >= 0 &&
            Address?.state?.value.length >= 0 &&
            isChecked
          ) {
            if (
              checkNetworkAndBalance(+usdPrice?.crypto, cryptoTaxConvertion)
            ) {
              onClickMakeOffer(
                +usdPrice?.crypto,
                +usdPrice?.usd,
                moment(date).utc().format(),
                {
                  ...Address,
                  state: Address?.state?.lable,
                },
                selectedCurrency,
                {
                  cryptoTaxPrice: +cryptoTaxConvertion,
                  cryptoTotalPrice: cryptoTotalPrice,
                  USDUnitprice: cryptoBID,
                  taxPercentage: +truncateDecimal(TaxPercent, 4),
                }
              );
            }
          }
        }
      }
    };

    const handleSwap = (taxData: IGetTax) => {
      const cryptoTotalPriceMakeOffer = +truncateDecimal(
        +usdPrice?.crypto + cryptoTaxValue,
        4
      );
      const cryptoTotalPriceBuyNow = +truncateDecimal(
        +NFTDetailsState?.price[1]?.value + cryptoTaxValue,
        4
      );
      const TaxPercent =
        ((taxData?.totalTaxAmount ?? 0) / (taxData?.totalTaxedPrice ?? 0)) *
        100;
      setIsMakeOfferState(false);
      setIsSwap(false);
      setIsLoading(true);
      if (taxData) {
        setCurrencyCode("");
        handleSwapAmount(
          isBuy ? NFTDetailsState?.price[1]?.value : +usdPrice.crypto,
          cryptoTaxValue,
          +usdPrice.usd,
          moment(date).utc().format(),
          {
            ...Address,
            state: Address?.state?.lable,
          },
          selectedCurrency,
          {
            cryptoTaxPrice: cryptoTaxValue,
            cryptoTotalPrice: isBuy
              ? cryptoTotalPriceBuyNow
              : cryptoTotalPriceMakeOffer,
            USDUnitprice: cryptoBID,
            taxPercentage: +truncateDecimal(TaxPercent, 4),
          }
        );
      }
    };

    const isDisable = () => {
      return (
        Address?.postalCode.length < 3 ||
        Address?.city.length === 0 ||
        Address?.street.length === 0 ||
        Address?.country?.value.length === 0 ||
        Address?.state?.value.length === 0 ||
        !isChecked ||
        kycStatusVal ||
        contactUs
      );
    };

    return (
      <Modal
        {...props}
        open={makeOfferModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 999,
          backgroundColor: "gba(0, 0, 0, 0.5)",
          // backdropFilter: "blur(4px)",
          overflow: "auto",
        }}>
        <Box sx={style}>
          <Box className="make-offer-subContainer">
            <Grid container className="offer-title-grid">
              <Grid item xs={10} lg={12}>
                <Typography className="offer-title">
                  {isBuy ? "BUY NOW" : "MAKE OFFER"}
                </Typography>
              </Grid>
              <Grid item xs={2} className="close-icon">
                <Typography
                  align="right"
                  onClick={() => {
                    setCurrencyCode("");
                    onHandleClose();
                  }}>
                  <CloseIcon style={{ color: "#9E9E9E" }} />
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} lg={6} className="offer-image-grid">
                <Box>
                  <NFTPreview
                    image={image}
                    secondImage={""}
                    animationURL={NFTDetailsState?.animationURL ?? ""}
                    mediaImage={image}
                    mediaExtension={NFTDetailsState?.mediaExtension}
                  />
                </Box>
                <Grid container>
                  <Grid item xs={12} sx={{ paddingTop: "15px" }}>
                    <Typography className={"nft-deatils-title"}>
                      {NFTDetailsState?.artistName}
                    </Typography>
                    <Typography className={"nft-deatils-description"}>
                      {!isEmpty(NFTDetailsState?.name)
                        ? NFTDetailsState?.name
                        : String(NFTDetailsState?.tokenId ?? "")?.length > 10
                        ? truncateAddress(String(NFTDetailsState?.tokenId))
                        : NFTDetailsState?.tokenId}
                    </Typography>
                    <Typography
                      sx={{ marginBottom: 1.5 }}
                      className={"nft-deatils-description"}>
                      {moment(NFTDetailsState?.year).format("YYYY")}
                    </Typography>
                    <hr className="line" />
                    <Box className="contract-details">
                      <Typography className={"header"}>
                        Contract address:
                      </Typography>
                      <Typography
                        onClick={() => {
                          const url = CheckNetworkTokens(
                            NFTDetailsState?.chainId,
                            NFTDetailsState?.contractAddress,
                            NFTDetailsState?.tokenId
                          );
                          window.open(url, "_blank");
                        }}
                        className={"nft-deatils-description text-underline"}>
                        {NFTDetailsState?.contractAddress?.substring(0, 6) +
                          "..." +
                          NFTDetailsState?.contractAddress?.substring(
                            NFTDetailsState?.contractAddress?.length - 4
                          )}
                      </Typography>
                    </Box>
                    <Box className="contract-details">
                      <Typography className={"header"}>Token ID:</Typography>
                      <Typography
                        onClick={() => {
                          if (NFTDetailsState?.tokenId)
                            window.open(NFTDetailsState?.tokenURI, "_ blank");
                        }}
                        className={"nft-deatils-description text-underline"}>
                        {String(NFTDetailsState?.tokenId ?? "")?.length > 10
                          ? truncateAddress(String(NFTDetailsState?.tokenId))
                          : NFTDetailsState?.tokenId}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                {isBuy ? (
                  <>
                    <Typography
                      sx={{ marginBottom: 1 }}
                      className={"offer-title"}>
                      PRICE
                    </Typography>
                    <Box sx={{ position: "relative" }}>
                      <div className={"select-dropdown open"}>
                        <span className="select-lable">SELECT CURRENCY</span>
                        {PriceList?.length === 1 ? (
                          <div className="single-wethaddress">
                            <SelectDropdown
                              selectValue={
                                PriceList?.filter(
                                  (ele: any) =>
                                    ele?.value === selectedCurrency?.id
                                )[0]?.lable
                              }
                              dropDownData={dropDownPrice()}
                              externalcss={"filter-select-dropdown"}
                            />
                          </div>
                        ) : (
                          <SelectDropdown
                            selectValue={
                              PriceList?.filter(
                                (ele: any) =>
                                  ele?.value === selectedCurrency?.id
                              )[0]?.lable
                            }
                            dropDownData={dropDownPrice()}
                            externalcss={"filter-select-dropdown"}
                          />
                        )}
                      </div>
                    </Box>
                    <Typography
                      sx={{ marginTop: 12 }}
                      className={"date-text usd-value"}>
                      {formatNumberToUSD(
                        +NFTDetailsState?.price[1]?.value * +cryptoBID,
                        4
                      ) +
                        " " +
                        NFTDetailsState?.price[0]?.unit}
                    </Typography>
                    <hr className="line" />
                  </>
                ) : (
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <Typography
                        sx={{ marginBottom: 1 }}
                        className={"offer-title"}>
                        PRICE
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={5.7}>
                      <Box sx={{ position: "relative" }}>
                        <div className={"select-dropdown with-input"}>
                          <span className="select-lable">
                            {"PRICE & CURRENCY"}
                          </span>
                          <Box sx={{ display: "flex" }}>
                            <input
                              ref={inputRef}
                              type="text"
                              value={usdPrice?.crypto}
                              defaultValue={"zero"}
                              className="input-make-offer"
                              placeholder="0.00"
                              onChange={(e) => {
                                const val = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                if (val && val?.length <= 6) {
                                  const matcher = new RegExp(
                                    `^[0-9]*\\.?[0-9]{0,${4}}$`
                                  );
                                  if (val === "" || matcher.test(val)) {
                                    onChangeCrypto(val);
                                  }
                                } else if (val?.length === 0) {
                                  onChangeCrypto("");
                                }
                              }}
                            />
                            <div className="single-wethaddress">
                              <SelectDropdown
                                selectValue={
                                  PriceList?.filter(
                                    (ele: any) =>
                                      ele?.value === selectedCurrency?.id
                                  )[0]?.lable
                                }
                                dropDownData={dropDownPrice()}
                                externalcss={"filter-select-dropdown"}
                              />
                            </div>
                          </Box>
                        </div>
                      </Box>
                      <Typography
                        sx={{ marginTop: 12 }}
                        className={"date-text"}>
                        {isEmpty(usdPrice?.usd)
                          ? "0"
                          : formatNumberToUSD(+usdPrice?.usd)}{" "}
                        {NFTDetailsState?.price[0]?.unit || " USD"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={0.5} />
                    <Grid item xs={12} lg={5.7}>
                      <Box sx={{ position: "relative" }}>
                        <div className={"select-dropdown open"}>
                          <span className="select-lable">EXPIRATION</span>
                          <SelectDropdown
                            selectValue={
                              Filter.filter(
                                (ele) => ele?.value === FilterData
                              )[0]?.lable
                            }
                            dropDownData={dropDownDataView()}
                            externalcss={"filter-select-dropdown"}
                          />
                        </div>
                      </Box>
                      {Filter.filter((ele) => ele?.value === FilterData)[0]
                        ?.lable !== "Custom" ? (
                        <Box className="date-box">
                          <Typography
                            sx={{ marginTop: 10 }}
                            className="date-text">
                            {date}
                          </Typography>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <hr className="line" />
                    </Grid>
                  </Grid>
                )}
                {+usdPrice?.crypto > 0 && +usdPrice?.crypto < 0.04 && (
                  <Box className={"errorMsg"}>
                    {+usdPrice?.crypto > 0 && +usdPrice?.crypto < 0.04
                      ? `Make offer above 0.04 ${
                          selectedCurrency?.currency ||
                          NFTDetailsState?.price[0]?.unit
                        }`
                      : ""}
                  </Box>
                )}
                {networkValidation?.status !== "" && (
                  <Grid item xs={12} style={summaryStyle.warningContainer}>
                    {networkValidation?.status === "NETWORK" && (
                      <Typography style={summaryStyle.warningText}>
                        Please{" "}
                        <Typography
                          display="inline"
                          onClick={handleSwitchNetwork}
                          style={summaryStyle.underlineStyle}>
                          <b>Switch to {NftNetworkDetails?.name}</b>
                        </Typography>{" "}
                        in order to complete checkout.
                      </Typography>
                    )}
                    {networkValidation?.status === "SWAP" && (
                      <Typography style={summaryStyle.warningText}>
                        {networkValidation?.message}
                        <Typography
                          display="inline"
                          onClick={() => {
                            setIsLoading(true);
                            setCurrencyCode("USD");
                            setIsSwap(true);
                          }}
                          style={summaryStyle.underlineStyle}>
                          <b>Click here to proceed.</b>
                        </Typography>
                      </Typography>
                    )}
                    {networkValidation?.status === "NO_BALANCE" && (
                      <Typography style={summaryStyle.warningText}>
                        {networkValidation?.message}
                      </Typography>
                    )}
                    {networkValidation?.status === "INVALID_ZIPCODE" && (
                      <Typography style={summaryStyle.warningText}>
                        {networkValidation?.message}
                      </Typography>
                    )}
                  </Grid>
                )}

                {organization?.w8Form && kycStatusVal && (
                  <Grid item>
                    <Box className={"error-card"}>
                      <Typography>
                        Please{" "}
                        <b
                          onClick={() => {
                            window.open(userRoleContext.link, "_blank");
                          }}>
                          complete your profile
                        </b>
                        {isBuy ? " to buy " : " to submit an offer "}
                        more than 10K USD.
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {organization?.w8Form && contactUs && (
                  <Grid item>
                    <Box className={"error-card"}>
                      <Typography>
                        There is a problem retrieving your account information{" "}
                        <b
                          onClick={() => {
                            window.open(userRoleContext.link, "_blank");
                          }}>
                          Please contact us.
                        </b>
                      </Typography>
                    </Box>
                  </Grid>
                )}

                <Grid container className={"select-filed"}>
                  <Grid item xs={12}>
                    <Typography className="offer-exp">{"Billing"}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={12}
                    className="select-box"
                    sx={{ marginBottom: 2 }}>
                    <Box className="boxstyle">
                      <Typography
                        sx={{ marginBottom: 1 }}
                        className={"input-tag"}>
                        STREET ADDRESS
                      </Typography>

                      <input
                        type="text"
                        className="input-box"
                        placeholder="123 Blockchain ave"
                        value={Address?.street}
                        onChange={onChangeStreet}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={5.7}
                    sx={{ marginBottom: 2 }}
                    className="country-select-dropdown">
                    <CountryStateSelector
                      placeholder="Choose country"
                      lable="SELECT COUNTRY"
                      type="country"
                      value={Address?.country}
                      onChangeData={onChangeCountry}
                      disabled={false}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} lg={0.5} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={5.7}
                    sx={{ marginBottom: 2 }}
                    className="country-select-dropdown">
                    <CountryStateSelector
                      placeholder="Choose State"
                      lable="SELECT STATE"
                      type="state"
                      value={Address?.state}
                      onChangeData={onChangeState}
                      state={Address?.country?.value ?? ""}
                      disabled={Address?.country?.value?.length === 0}
                    />
                  </Grid>
                  {/* state input box Grid*/}

                  {/* select state according to country input box Grid */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={5.7}
                    className="select-box"
                    sx={{ marginBottom: 2 }}>
                    <Box className="boxstyle">
                      <Typography
                        sx={{ marginBottom: 1 }}
                        className={"input-tag"}>
                        CITY
                      </Typography>

                      <input
                        type="text"
                        className="box-input"
                        placeholder="New York"
                        value={Address?.city}
                        onChange={onChangeCity}
                        disabled={Address?.country?.value?.length === 0}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} lg={0.5} />
                  <Grid item xs={12} sm={12} lg={5.7} className="select-box">
                    <Box className="boxstyle">
                      <Typography
                        sx={{ marginBottom: 1 }}
                        className={"input-tag"}>
                        ZIPCODE
                      </Typography>

                      <input
                        ref={isBuy ? inputRef : zipcodeRef}
                        type="text"
                        value={Address?.postalCode}
                        className="box-input"
                        placeholder="00000"
                        maxLength={8}
                        disabled={Address.country?.value?.length === 0}
                        onChange={(e) => {
                          setNetworkValidation({
                            message: "",
                            status: "",
                          });
                          let reg = null;
                          if (Address.country?.value === "US") {
                            reg = /^[0-9]*$/;
                          } else {
                            reg = /[a-zA-Z0-9]/;
                          }
                          if (
                            (e.target.value === "" ||
                              reg.test(e.target.value)) &&
                            e.target.value.length <= 8
                          )
                            setAddress({
                              ...Address,
                              postalCode: e.target.value.toUpperCase(),
                            });
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="agree-container"></Grid>
                  <Grid item xs={12} lg={12}>
                    <hr className="line mobile-view" />
                  </Grid>
                  {!isBuy &&
                    NFTDetailsState?.latestOffer &&
                    NFTDetailsState?.latestOffer?.price && (
                      <>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          sx={{
                            marginTop: 1,
                            display: "flex",
                            justifyContent: "space-between",
                          }}>
                          <Typography className={"offer-title"}>
                            LATEST OFFER
                          </Typography>
                          <Box sx={{ display: "flex" }}>
                            <Typography className="latest-offer-price">
                              {NFTDetailsState?.latestOffer?.price[1]?.value +
                                " " +
                                NFTDetailsState?.latestOffer?.price[1]?.unit}
                            </Typography>
                            <Typography className="date-text">
                              {formatNumberToUSD(
                                NFTDetailsState?.latestOffer?.price[0]?.value
                              ) +
                                " " +
                                NFTDetailsState?.latestOffer?.price[0]?.unit}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={12} sx={{ marginTop: 0.5 }}>
                          <hr className="line" />
                        </Grid>
                      </>
                    )}
                  <Grid item xs={12} lg={12} className="agree-container">
                    <Checkbox
                      checked={isChecked}
                      onChange={(e: any) => setIsChecked(e.target.checked)}
                    />
                    <Typography className="terms-and-conditions">
                      I agree to the{" "}
                      <a
                        target="_blank"
                        href="/terms-of-service"
                        className="agree-link">
                        Terms of Service
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href="/conditions-of-business"
                        className="agree-link">
                        Conditions of Business.
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} sx={{ marginTop: 1 }}>
                    {isBuy ? (
                      <SecondaryButton
                        buttontype="primary"
                        disabled={
                          !isEmpty(networkValidation?.status) || isDisable()
                        }
                        isLoading={isLoading}
                        onClick={async () => {
                          const response = postalCodes.validate(
                            Address?.country?.value,
                            Address?.postalCode
                          );
                          if (typeof response === "boolean") {
                            setIsLoading(true);
                            setCurrencyCode("USD");
                            setIsMakeOfferState(true);
                            setIsSwap(false);
                          } else {
                            setNetworkValidation({
                              status: "INVALID_ZIPCODE",
                              message:
                                "Please enter a valid ZIP or Postal code.",
                            });
                          }
                        }}>
                        Next
                      </SecondaryButton>
                    ) : (
                      <SecondaryButton
                        disabled={
                          isEmpty(date) ||
                          isNaN(+usdPrice?.crypto) ||
                          +usdPrice?.crypto < 0.04 ||
                          networkValidation?.status !== "" ||
                          isDisable()
                        }
                        isLoading={isLoading}
                        onClick={() => {
                          const response = postalCodes.validate(
                            Address?.country?.value,
                            Address?.postalCode
                          );
                          if (typeof response === "boolean") {
                            setIsLoading(true);
                            setCurrencyCode("USD");
                            setIsMakeOfferState(true);
                            setIsSwap(false);
                          } else {
                            setNetworkValidation({
                              status: "INVALID_ZIPCODE",
                              message:
                                "Please enter a valid ZIP or Postal code.",
                            });
                          }
                        }}>
                        Make Offer
                      </SecondaryButton>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={12} sx={{ marginTop: 1 }}>
                    <SecondaryButton
                      buttontype="secondary"
                      onClick={() => {
                        setCurrencyCode("");
                        onHandleClose();
                      }}>
                      Cancel
                    </SecondaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  }
)`
  .make-offer-subContainer {
    max-height: calc(100vh - 100px);
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
  }
  .input-make-offer {
    border: none;
    background: ${palette.white};
    color: ${palette.black};
    font-weight: 400;
    font-size: 13px;
    width: 65px;
    position: absolute;
    z-index: 9999999999999;
    top: 40px;
    left: 2px;
    padding-left: 12px;
  }
  .error-card {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: ${palette.warningBackground};
    margin-bottom: 10px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid rgb(189, 34, 0);
    p {
      font-size: 12px;
      color: ${palette.warning};
      b {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .offer-title {
    font-family: BentonSans;
    font-weight: 700;
    font-size: 12px;
    color: ${palette.black};
  }
  .offer-title-grid {
    margin-bottom: 22px;
  }
  .close-icon {
    display: none;
    p {
      margin-top: -5px;
    }
  }
  .boxstyle {
    color: ${palette.black};
    border: 1px solid ${palette.hoverColor};
    height: 72px;
    border-radius: 5px;
    padding: 16px 16px 16px 17px;
    .usd-cal {
      float: right;
      font-family: BentonSans;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: ${palette.black};
      opacity: 0.5;
    }
  }
  .input-tag {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: ${palette.black};
  }
  .line {
    height: 1px;
    color: ${palette.black};
    opacity: 0.2;
    margin: 16px 0px 16px 0px;
  }
  .offer-exp {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    color: ${palette.black};
    margin-bottom: 16px;
  }
  .select-filed {
    margin-bottom: 32px;
  }
  .isBuy {
    margin-bottom: 85px;
  }
  .select-box {
    position: relative;
  }
  .date-grid {
    padding-left: 8px;
    cursor: pointer;
  }
  #filter-select-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    .sort-input-change {
      border-radius: 4px;
      background: ${palette.white};
      z-index: 999999;
    }
    .menu-item-value {
      height: 54px;
      font-family: BentonSans;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${palette.black};
    }
  }
  .select-dropdown {
    position: relative;
    &.with-input {
      .menu-item-value {
        padding: 38px 0px 16px 65px !important;
      }
    }
    .select-lable {
      color: ${palette.black};
      font-family: BentonSans;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 5px;
      position: absolute;
      z-index: 999999999;
      top: 20px;
      left: 13px;
    }
    .MuiGrid-grid-xs-2 {
      padding-top: 20px !important;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-right: 16px;
    }
    .single-wethaddress {
      pointer-events: none;
      .MuiGrid-grid-xs-2 {
        opacity: 0;
      }
    }
    .sort-input-change .sort-icon-adjustment {
      padding-right: 12px !important;
      display: flex;
      justify-content: flex-end;
    }
    .menu-item-value {
      height: 74px !important;
      padding: 38px 0px 16px 12px !important;
    }
    .menu-item {
      padding: 8px 12px !important;
      &.open {
        padding: 16px 0px 8px 12px !important;
      }
    }
    .select-dropdown .menu-item-value {
      padding: 40px 0px 16px 12px !important;
    }
    #filter-select-dropdown {
      .sort-input-change {
        z-index: 99999999;
      }
    }
  }
  .disable {
    border: 1px solid ${palette.hoverColor};
    cursor: not-allowed !important;
    opacity: 0.5;
    background: ${palette.white};
  }
  .enable-button {
    text-transform: none;
    padding: 16px 10px;
    letter-space: 10px;
    width: 100%;
    height: 54px;
    background: linear-gradient(
        0deg,
        rgba(66, 0, 96, 0.4),
        rgba(66, 0, 96, 0.4)
      ),
      linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.black};
  }
  .normal-button {
    text-transform: none;
    padding: 16px 10px;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 0.0001);
    border: 1px solid ${palette.hoverColor};
    border-radius: 3px;
    cursor: pointer;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    color: ${palette.black};
  }
  .latest-offer {
    margin: 16px 0 12px 0;
  }
  .latest-offer-last {
    margin: 10px 0 32px 0px;
  }
  .latest-offer-title {
    font-family: BentonSans;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: ${palette.black};
    span {
      padding-left: 8px;
    }
  }
  .value {
    font-size: 15px;
    font-weight: 400;
  }
  hr {
    color: ${palette.black};
  }
  .errorMsg {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    color: red;
    text-align: left;
    position: relative;
    margin-bottom: 22px;
  }
  .img-style {
    width: 420px;
    height: 492px;
  }
  .iframe-styles {
    object-fit: contain;
  }
  input:focus {
    outline: none;
  }
  input[type="text"]:disabled {
    cursor: not-allowed;
  }
  input:disabled::-webkit-input-placeholder {
    color: ${palette.black};
    opacity: 0.5;
  }
  .latest-offer-price {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: ${palette.black};
    margin-right: 10px;
  }
  .box-input {
    border: none;
    background: ${palette.white};
    color: ${palette.black};
    font-weight: 400;
    font-size: 13px;
    width: 100%;
  }
  .input-box {
    border: none;
    background: ${palette.white};
    color: ${palette.black};
    font-weight: 400;
    font-size: 14px;
    width: 100%;
  }
  .agree-container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .terms-and-conditions {
    font-weight: 400;
    font-size: 12px;
    font-family: "BentonSans";
    margin-left: 5px;
    color: ${palette.black};
  }
  .agree-link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${palette?.navyBlue};
    }
  }
  .date-text {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: ${palette.gray};
  }
  .date-box {
    width: 232px;
    height: 56px;
    padding-top: 16px;
  }
  .nft-deatils-description {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  .nft-deatils-title {
    font-family: Mercury Display;
    font-weight: 325;
    font-size: 25px;
    line-height: 30px;
  }
  .border-line-grid {
    border: 1px solid ${palette.black};
    opacity: 0.2;
  }
  .contract-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .header {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }
  .text-underline {
    text-decoration-line: underline;
    cursor: pointer;
  }
  .offer-image-grid {
    padding-right: 20px;
  }
  @media screen and (max-width: ${EViewSize.mobile}) {
    .img-style {
      width: 100%;
      height: 363px;
    }
    .offer-image-grid {
      margin-bottom: 32px;
      padding-right: 0px;
    }
    .select-box {
      margin-bottom: 8px;
    }
    .offer-title {
      margin-bottom: 16px;
    }
    .latest-offer-title {
      display: grid;
    }
    .close-icon {
      display: revert;
    }
    .date-grid {
      padding-left: 0px;
      margin-top: 65px;
    }
    .country-select-dropdown {
      margin-bottom: 20px;
    }
    .select-filed {
      margin-top: 40px;
    }
    .mobile-view {
      display: none;
    }
    .date-box {
      margin-top: 10px;
      margin-bottom: 25px;
    }
    .input-make-offer {
      top: 50px;
    }
    .usd-value {
      margin-top: 40%;
    }
  }
`;
